import React, { useState, useEffect } from 'react';
import ImgPlaceholder from '../../images/img-placeholder.png'
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './create.css'
import fb from '../../images/facebook.png';
import twitter from '../../images/twitter copy.png';
import discord from '../../images/discord copy.png';
import medium from '../../images/medium.png';
import insta from '../../images/instagram.png';
import www from '../../images/world-wide-web.png';
import telegram from '../../images/telegram copy.png';
import { Loader } from '../loader';
import { toast } from 'react-toastify';
import { creatingCollAllowed } from '../../helpers/helpers';

let containsSpecialCharacter = /[`!#%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const addCollectionRequest = (image, bannerImage, featuredImage, useraddress, name, symbol, royalty, selectedCateg, desc, wwwLink, fbLink, instaLink, twitterLink, discordLink, telegramLink, mediumLink) => {

  var formdata = new FormData();
  formdata.append("mainImage", image, image.name);
  if (bannerImage){
    formdata.append("bannerImage", bannerImage, bannerImage.name);
  }
  if (featuredImage){
    formdata.append("featuredImage", featuredImage, featuredImage.name);
  }
  formdata.append("name", name);
  formdata.append("description", desc);
  formdata.append("symbol", symbol);
  formdata.append("royalty", royalty);
  if (selectedCateg){
    formdata.append("category", selectedCateg);
  }
  formdata.append("www", wwwLink);
  formdata.append("fb", fbLink);
  formdata.append("insta", instaLink);
  formdata.append("twitter", twitterLink);
  formdata.append("discord", discordLink);
  formdata.append("telegram", telegramLink);
  formdata.append("medium", mediumLink);
  formdata.append("address", useraddress);

  var requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(useraddress.toLowerCase())
    },
    body: formdata,
    redirect: 'follow'
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/collections/add/${useraddress}`, requestOptions)
  .then(r => r.json())
}
const adminTokenValid = async () => {
  if (localStorage.getItem('token')){
    return fetch(`${process.env.REACT_APP_BASE_URL}/admin/check/jwt`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json()).then(r => r.valid);
  }
  else{
    return false;
  }
}

export default function CreateCollection () {
  const [image, setImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [symbol, setSymbol] = useState('');
  const [royalty, setRoyalty] = useState(0);
  const [status, setStatus] = useState('');
  const [showMint, setShowMint] = useState(false);
  const [item, setItem] = useState({});
  const [createModal, setCreateModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const authedUser = useSelector(state => state.authedUser);
  const navigate = useNavigate();

  useEffect(() => {
    creatingCollAllowed(authedUser.authedUser.address.toLowerCase()).then((allowed) => {
      if (allowed){
      }
      else{
        adminTokenValid().then(r => {
          if (r){

          }
          else{
            navigate('/marketplace');            
          }
        })
      }
    });
  }, [authedUser.authedUser]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`).then(r => r.json())
    .then(r => {
      if (r.success){
        setCategories(r.categories);
      }
    })
  }, []);

  const fileChangeHandler = (e, type) => {
    if (e.target.files.length > 0){
        let sizeInMB = (e.target.files[0].size/1024)/1024;
        if (sizeInMB <= 10){
          if (type === 'main'){
            setImage(e.target.files[0]);
          }
          else if (type === 'featured'){
            setFeaturedImage(e.target.files[0]);
          }
          else if (type === 'banner'){
            setBannerImage(e.target.files[0]);
          }
        }
        else{
          toast('Size should be less than 10', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Size should be less than 10');
        }
    }
  }

  
  const createCollection = async () => {
    const selectedCateg = document.getElementById('custom-select-collection-categ').value;
    const wwwLink = document.getElementById('www-link').value;
    const fbLink = document.getElementById('fb-link').value;
    const instaLink = document.getElementById('insta-link').value;
    const twitterLink = document.getElementById('twitter-link').value;
    const discordLink = document.getElementById('discord-link').value;
    const telegramLink = document.getElementById('telegram-link').value;
    const mediumLink = document.getElementById('medium-link').value;
    if (authedUser.authedUser.address){
      let allowed = await creatingCollAllowed(authedUser.authedUser.address.toLowerCase());
      if (allowed){
        console.log(name, desc, image);
        if (name !== '' && image !== '' && royalty !== '' && symbol !== '' && desc !== ''){
          // setCreateModal(true);
          if (parseFloat(royalty) > 10){
            toast('Royalty cannot be greater than 10 percent', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('Royalty cannot be greater than 10 percent');
          }
          else if (containsSpecialCharacter.test(name)){
            toast('Name of collection cannot include special characters except @ or $', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('Name of collection cannot include special characters except @ or $');
          }
          else{
            let nameToCheck = name.trim().toLowerCase().replace(/ /g,"-");
            const n = await fetch(`${process.env.REACT_APP_BASE_URL}/collections/name/${nameToCheck}`).then(r => r.json());
            if (n.success){
              try{
                setCreateModal(true);
                setStatus('Creating Collection');
                console.log(image, bannerImage, featuredImage, authedUser.authedUser.address, name, symbol, royalty, selectedCateg, desc, wwwLink, fbLink, instaLink, twitterLink, discordLink, telegramLink, mediumLink);
                let r = await addCollectionRequest(image, bannerImage, featuredImage, authedUser.authedUser.address, name, symbol, royalty, selectedCateg, desc, wwwLink, fbLink, instaLink, twitterLink, discordLink, telegramLink, mediumLink);
                if (r.success){
                  navigate(`/collection/${r.collection.trimmedname}`);
                }
                setCreateModal(false);
              }
              catch(e){
                console.log('hereeee');
                console.log(e);
                setCreateModal(false);
                setStatus('Not Created');
                toast('Unable to create!', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  theme: 'dark'
                });
                // alert('Unable to create!');
              }    
            }
            else{
              toast('Collection with same name already exist', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert('Collection with same name already exist');
            }
          }
        }
        else{
          let msg = [];
          if (name === ''){
            msg.push('Name');
          }
          if (image === ''){
            msg.push('Image');
          }
          if (royalty === ''){
            msg.push('Royalty');
          }
          if (symbol === ''){
            msg.push('Symbol');
          }
          if (desc === ''){
            msg.push('Description');
          }
          toast(`Fill the complete form first!\nEmpty fields: ${msg.map((m, i) => `${m} `)}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Fill the complete form first!');
        }
      }
      else{
        toast('This feature is blocked. Contact admin', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    }
    else{
      toast('Connect Wallet', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      // alert('Connect Wallet');
    }
  }

  if (authedUser.authedUser.address){
    return(
      <div className='create-colMail'>
        <div className='container mt-4'>
          <div className='row'>
            <h1 className='col-12 create-header font-700'>
              Create Collection
            </h1>
          </div>
        </div>
          
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <hr style={{
                backgroundImage: 'linear-gradient(90deg, rgb(102 96 209) 0%, rgb(153 38 223) 50%, rgb(211 0 239) 100%)',
                height: '2px'
                }} />
            </div>
          </div>
        </div>
        
        <div className='container create-middle'>
          <div className="row">
            <div className="col-md-8">
              <div className='row mt-4 name-and-symbol'>
                <div className="col-12 col-sm-4" style={{color: 'white'}}>
                  <h5 style={{fontWeight: 600}}>Name</h5>
                  <input type="text" placeholder="Name of your Collection" id="title" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="col-12 col-sm-4" style={{color: 'white'}}>
                  <h5 style={{fontWeight: 600}}>Symbol</h5>
                  <input type="text" placeholder="Symbol of your Collection" id="title" onChange={(e) => setSymbol(e.target.value)} />
                </div>
              </div>
              <div className='row mt-4 collection-select'>
                <div className="col-12 mt-3 mt-sm-0 col-sm-6" style={{color: 'white'}}>
                  <h5 style={{fontWeight: 600}}>Category</h5>
                  <select className="px-1" id="custom-select-collection-categ" style={{width: 'inherit'}}>
                    <option value="">-</option>
                    {categories.map((c, ind) => {
                      return(
                        <option value={c._id}>{c.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='row mt-4'>
                <div className="col-12 col-sm-8" style={{color: 'white'}}>
                    <h5 style={{fontWeight: 500}}>Description</h5>
                    <textarea type="text" rows="10" placeholder="0 of 1000 characters used." value={desc} autoComplete="off" id="descript" onChange={(e) => setDesc(e.target.value)}></textarea>
                </div>
              </div>
              <div className="row mt-4 mb-3">
                <div className="col-12 text-white">
                  <h5 style={{fontWeight: 500}}>Links</h5>
                </div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={www} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.website.com" id="www-link" className="social-link-input" />
                </div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={fb} className="social-img" style={{paddingLeft: '20px', paddingRight: '20px'}} />
                  <input type="text" rows="10" placeholder="https://www.facebook.com" id="fb-link" className="social-link-input" />
                </div>
                <div className="col-12 col-sm-4"></div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={discord} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.discord.com" id="discord-link" className="social-link-input"/>
                </div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={medium} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.medium.com" id="medium-link" className="social-link-input"/>
                </div>
                <div className="col-12 col-sm-4"></div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={twitter} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.twitter.com" id="twitter-link" className="social-link-input"/>
                </div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={telegram} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.telegram.com" id="telegram-link" className="social-link-input"/>
                </div>
                <div className="col-12 col-sm-4"></div>
                <div className="col-12 col-sm-4 my-2" style={{color: 'white', position: 'relative'}}>
                  <img src={insta} className="social-img" />
                  <input type="text" rows="10" placeholder="https://www.instagram.com" id="insta-link" className="social-link-input"/>
                </div>
              </div>
    
              
              <div className="row mt-4 mb-3">
                <div className="col-12 text-white">
                  <h5 style={{fontWeight: 500}}>Royalties</h5>
                  <p style={{fontSize: '18px', fontStyle: 'italic', marginBottom: '10px', color: 'grey'}}>Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly
                      to a payout address of your choosing.</p>
                </div>
                <div className="col-6 col-sm-4" style={{color: 'white'}}>
                  <input type="number" min="0" max="10" placeholder="Royalty Percent" id="royaltyfee" value={royalty} onChange={(e) => {if ( e.target.value !== "-"){if ((20 >= parseInt(e.target.value) && parseInt(e.target.value) >= 0) | e.target.value === ""){setRoyalty(e.target.value)}}}} />
                </div>
              </div>
              <div className='row properties'></div>
              <div className='row create mt-4'>
                <div className="col-12 col-sm-6">
                  <button onClick={() => createCollection()} className="create-btn" >Create</button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className='row img-div'>
                <div className='col-12'>
                  <h4 style={{fontWeight: 600}}>Main Item</h4>
                  <p>Main Item: File types supported : JPG, PNG, GIF, SVG </p>
                </div>
                <div className='col-12 mt-3'>
                  <input type="file" style={{display: 'none'}} accept="image/*" id="input-file" onChange={(e) => fileChangeHandler(e, "main")} />
                  {image ? (
                    <div onClick={() => document.getElementById("input-file").click()} className='card onsale-item'>
                      <img variant="top" className="card-img item-img" src={URL.createObjectURL(image)} />
                    </div>
                  ) : (
                    <div id="img-upload" onClick={() => document.getElementById("input-file").click()}>
                      <img className='gallery' src={ImgPlaceholder} />
                    </div>
                  )}
                </div>
              </div>
              <div className='row img-div mt-3'>
                <div className='col-12'>
                  <h4 style={{fontWeight: 600}}>Banner Item</h4>
                  <p>Banner Item: File types supported : JPG, PNG, GIF, SVG </p>
                </div>
                  <div className='col-12  mt-3'>
                    <input type="file" style={{display: 'none'}} accept="image/*" id="banner-input-file" onChange={(e) => fileChangeHandler(e, 'banner')} />
                    {bannerImage ? (
                      <div className='card onsale-item'>
                        <img variant="top" onClick={() => document.getElementById("banner-input-file").click()} className="card-img item-img" src={URL.createObjectURL(bannerImage)} />
                      </div>
                    ) : (
                      <div id="img-upload" onClick={() => document.getElementById("banner-input-file").click()}>
                        <img  className='gallery' src={ImgPlaceholder} />
                      </div>
                    )}
                  </div>
              </div>
              <div className='row img-div mt-3'>
                <div className='col-12'>
                  <h4 style={{fontWeight: 600}}>Featured Item</h4>
                  <p>Featuerd Item: File types supported : JPG, PNG, GIF, SVG </p>
                </div>
                <div className='col-12 mt-3'>
                  <input type="file" style={{display: 'none'}} accept="image/*" id="featured-input-file" onChange={(e) => fileChangeHandler(e, 'featured')} />
                  {featuredImage ? (
                    <div className='card onsale-item'>
                      <img variant="top" onClick={() => document.getElementById("featured-input-file").click()} className="card-img item-img" src={URL.createObjectURL(featuredImage)} />
                    </div>
                  ) : (
                    <div id="img-upload"  onClick={() => document.getElementById("featured-input-file").click()}>
                      <img className='gallery' src={ImgPlaceholder} />
                    </div>
                  )}
                </div>
    
              </div>
            </div>
          </div>
          
        </div>
  
        
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <hr style={{
                backgroundImage: 'linear-gradient(90deg, rgb(102 96 209) 0%, rgb(153 38 223) 50%, rgb(211 0 239) 100%)',
                height: '2px'
                }} />
            </div>
          </div>
        </div>
        <Loader loader={createModal} msg={status} />
      </div>
    );  
  }
  else{
    return(
      <Navigate to="/marketplace" />
    );
  }
}