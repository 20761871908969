import React, { useEffect, useState } from 'react';
import fb from '../../images/fb.png';
import twitter from '../../images/twitte.png';
import insta from '../../images/insta.png';
import telegram from '../../images/telegramfooter.png';
import discordlink from '../../images/discordlink.png';
import mediumlink from '../../images/mediumlink.png';
import websitelink from '../../images/websitelink.png';
import profile_placeholder_banner from '../../images/profile_placeholder_image.jpg'
import './collection.css';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import FollowersTab from './followersTab';
import AllItemsTab from './allItemsTab';
import AuctionsTab from './auctionsTab';
import SaleTab from './saleTab';
import { toast } from 'react-toastify';
import BlockedItemModal from '../BlockedItemModal/blockedUserModal';

export default function CollectionDetail(){
  let { trimmedname } = useParams();
  const [collection, setCollection] = useState({});
  const [totalItemsOnAuction, setTotalOnAuc] = useState(0);
  const [totalItemsOnNoSale, setTotalNotOnSale] = useState(0);
  const [totalItemsOnSale, setTotalOnSale] = useState(0);
  const [unique, setUnique] = useState(0);
  const [activeTab, setActiveTab] = useState('owned');
  const [loading, setLoading] = useState(true);

  const authedUser = useSelector(state => state.authedUser);

  const followReq = () => {
    if (authedUser.authedUser.address){
      fetch(`${process.env.REACT_APP_BASE_URL}/collections/follow/${collection?._id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
        }
      }).then(r => r.json())
      .then(res => {
        setCollection({...collection, followedBy: res.collection?.followedBy});
      })
    }
    else{
      
      toast('Please connect your wallet first', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      // alert('Please connect your wallet first')
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/collections/details/${trimmedname}`)
    .then((response) => response.json())
    .then((res) => {
      if (res.collection){
        setCollection(res.collection);
        getStats(res.collection?._id);  
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    });
  }, [trimmedname]);

  const getStats = (col_id) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/collections/stat/${col_id}`)
    .then(r => r.json())
    .then(res => {
      setTotalOnAuc(res.onAuc);
      setTotalNotOnSale(res.notOnSale);
      setTotalOnSale(res.onSale);
      setUnique(res.owners);
    })
  }

  if (loading){
    return (
      <div className="create-loader">

      </div>
    );
  }
  else if (Object.keys(collection).length === 0){
    return (
      <h4>No collection with {trimmedname} exists!</h4>
    );
  }
  else if (collection.banned){
    return(
      <BlockedItemModal />
    );
  }
  else{

    return(
      <div className='container mt-5 text-white'>
        <div className='row mx-0'>
          <div className="col-12 col-lg-5 mt-3">
            <div className="pic-name-addrs">
              <img src={`${process.env.REACT_APP_BASE_URL}/${collection?.logo}`}  className="profile-pic-img" style={{border:'1px solid lightslategrey'}} />
              <div className="name-address">
                <span className="username text-white">{collection?.name}</span>
                <span className="address"  style={{wordBreak:'break-word'}}>{collection?.address}</span>
              </div>
            </div>
            <div className="user-desc">
              {collection?.desc}
            </div>
            <div>
              {collection?.facebook && (<>
                <div className="social-detail d-inline mx-2">
                    <div className="share-msg-btn">
                      <img onClick={()=>window.open(collection?.facebook.match(/^https?:/) ? collection?.facebook : '//' + collection?.facebook)} style={{wordBreak:'break-all',cursor:'pointer'}} src={fb} />
                    </div>
                </div>
              </>)}
              {collection?.instagram && (<>
                <div className="social-detail  d-inline mx-2">
                    <div className="share-msg-btn">
                      <img onClick={()=>window.open(collection?.instagram.match(/^https?:/) ? collection?.instagram : '//' + collection?.instagram)} style={{wordBreak:'break-all',cursor:'pointer'}} src={insta} />
                    </div>
                </div>
              </>)}
              {collection?.twitter && (<>
              <div className="social-detail d-inline mx-2">
                  <div className="share-msg-btn">
                    <img src={twitter} onClick={()=>window.open(collection?.twitter.match(/^https?:/) ? collection?.twitter : '//' + collection?.twitter)} style={{wordBreak:'break-all',cursor:'pointer'}}></img>
                  </div>
                </div>
              </>)}
              {collection?.website && (<>
                <div className="social-detail d-inline mx-2">
                  <div className="share-msg-btn">
                    <img  onClick={()=>window.open(collection?.website.match(/^https?:/) ? collection?.website : '//' + collection?.website)} style={{wordBreak:'break-all',cursor:'pointer'}} src={websitelink} />
                  </div> 
                </div>
              </>)}
              {collection?.discord && (<>
                <div className="social-detail d-inline mx-2">
                  <div className="share-msg-btn">
                    <img  onClick={()=>window.open(collection?.discord.match(/^https?:/) ? collection?.discord : '//' + collection?.discord)} style={{wordBreak:'break-all',cursor:'pointer'}} src={discordlink} />
                  </div>
                </div>
              </>)}
              {collection?.telegram && (<>
                <div className="social-detail d-inline mx-2">
                  <div className="share-msg-btn">
                    <img onClick={()=>window.open(collection?.telegram.match(/^https?:/) ? collection?.telegram : '//' + collection?.telegram)} style={{wordBreak:'break-all',cursor:'pointer'}} src={telegram} />
                  </div>
                </div>
              </>)}
              {collection?.medium && (<>
                <div className="social-detail d-inline mx-2">
                  <div className="share-msg-btn">
                    <img onClick={()=>window.open(collection?.medium.match(/^https?:/) ? collection?.medium : '//' + collection?.medium)} style={{wordBreak:'break-all',cursor:'pointer'}} src={mediumlink} />
                  </div>
              </div>
              </>)}
            </div>
  
            <div className="row">
              <div className="col-3">
                <button 
                onClick={() => followReq()} 
                className="follow-btn">{
                  collection?.followedBy?.includes(authedUser.authedUser?._id) ? 'Following' : 'Follow +'
                }</button>
                {/* <div className="share-msg-btn">
                  <img src={fb} />
                </div>
                <div className="share-msg-btn">
                  <img src={youtube} />
                </div> */}
              </div>
  
            </div>
            <div className="row">
              <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  Owner<br/>{unique} 
                </span>
              </div>
              {/* <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px'}}>
                  Value <br/> 10k
                </span>
              </div> */}
              <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  Total NFTs <br/> {totalItemsOnAuction+totalItemsOnNoSale+totalItemsOnSale} 
                </span>
              </div>
              <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  On Sale <br/> {totalItemsOnSale}
                </span>
              </div>
              <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  On Auction <br/> {totalItemsOnAuction} 
                </span>
              </div>
              <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  Royalty Fee <br/> {collection?.royalty_percent} 
                </span>
              </div>
              {/* <div className="col-6 col-sm-3 px-1 px-sm-2">
                <span className="share-msg-btn text-center w-100" style={{padding:'11px 11px',background: '#17e9d9ab',borderRadius:'13px',lineHeight:'22px'}}>
                  Trading Volume <br/> {collection?.tradingvol ? parseFloat(collection?.tradingvol).toFixed(2) : '0.0$'} 
                </span>
              </div> */}
              {/* <div className="col-4">
                <span className="share-msg-btn">
                  Not on Sale {totalItemsOnNoSale} NFTs
                </span>
              </div> */}
            </div>
  
          </div>
  
          <div className="col-12 col-lg-7 mt-5 mt-lg-3">
            <div className="d-flex justify-content-center"><img src={collection?.bannerImage ? `${process.env.REACT_APP_BASE_URL}/${collection?.bannerImage}` : profile_placeholder_banner} className="weird-img" /></div>
            <ul className="nav nav-pills nav-fill profile-view-tabs">
              <li className="nav-item" onClick={() => setActiveTab('auction')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'auction' ? 'active' : ''} id="auction">On Auction</span>
              </li>
              <li class="nav-item" onClick={() => setActiveTab('owned')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'owned' ? 'active' : ''} id="owned" >All</span>
              </li>
              <li class="nav-item" onClick={() => setActiveTab('created')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'created' ? 'active' : ''} id="created">Sale</span>
              </li>
              {/* <li class="nav-item" onClick={() => setActiveTab('liked')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'liked' ? 'active' : ''} id="liked" >Liked</span>
              </li> */}
              <li class="nav-item" onClick={() => setActiveTab('followers')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'followers' ? 'active' : ''} id="followers">Followers</span>
              </li>
              {/* <li class="nav-item" onClick={() => setActiveTab('following')}>
                <span style={{cursor: 'pointer'}} className={activeTab === 'following' ? 'active' : ''} id="following">Following</span>
              </li> */}
            </ul>
          </div>
  
  
          {activeTab === 'followers' ? (
            <>
              {collection?.followedBy.length > 0 ? <FollowersTab col_id={collection?._id} /> : (
                <div className="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3 mt-4">
                  <h4 className="text-center text-white my-5">No Items Found</h4>
                </div>
              )}
            </>
          ) : <></>}
          {activeTab === 'owned' ?
            <AllItemsTab col_id={collection?._id} />
          : <></>}
          {activeTab === 'auction' ?
            <>
              {totalItemsOnAuction > 0 ? (
                <AuctionsTab col_id={collection?._id} />
              ) : (
                <div className="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3 mt-4">
                  <h4 className="text-center text-white my-5">No Items Found</h4>
                </div>
              )}
          </> : <></>}
          {activeTab === 'created' ?
            <>
              {totalItemsOnSale > 0 ? (
                <SaleTab col_id={collection?._id} />
              ) : (
                <div className="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3 mt-4">
                  <h4 className="text-center text-white my-5">No Items Found</h4>
                </div>
              )}
          </> : <></>}
          
        </div>
      </div>
    );
  }

}