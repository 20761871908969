import React ,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

export default function BanNFTs(){
  let [bannedItems, setBannedItems] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/items/get-all-banned`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json()).then(res => {
      console.log(res);
      if (res.success){
        setBannedItems(res.bannedItems);
      }
    })
  }, []);

  const addToBanned = async () => {
    let details = document.getElementById('banned-item-details').value;
    // details = details.split(',');
    console.log({token_id: details});
    if (details){
      fetch(`${process.env.REACT_APP_BASE_URL}/items/add-to-banned`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({token_id: details})
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          setBannedItems(bannedItems.concat([res.item]));
        }
        else{
          alert(res.msg);
        }
      })
    }
    else{
      alert('Format incorrect');
    }
  }

  const removeFromBanned = async (id) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/items/remove-from-banned`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({item_id: id})
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        setBannedItems(bannedItems.filter(i => i._id !== id));
      }
      else{
        alert(res.msg);
      }
    })
  }
  const deleteNFT = (id) => {
    if (id){
      fetch(`${process.env.REACT_APP_BASE_URL}/items/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({id})
      }).then(r => r.json())
      .then(res => {
        alert(res.msg);
      });
    }
    else{
      alert('Please enter token id');
    }
  }
  return(
    <div className="container mt-3">
        <h2 className="mb-4 text-center text-white"  style={{fontFamily: 'Gotham-Font-Navbar'}}>Ban NFTs</h2>
      <div className="row text-center text-white">
        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter token id:</label><br/>
          <input type="text" id="banned-item-details" className="collectionFilter w-100 mb-2" placeholder='Ex: 1192' ></input>
          <button onClick={addToBanned} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Ban NFTs</button>
          <h4 className='mt-3'>Banned NFTs</h4>
          {bannedItems.map(c => (
            <FormGroup check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c.name}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeFromBanned(c._id)}>Remove</button>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => deleteNFT(c._id)}>Delete</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
    </div>
  );
}
