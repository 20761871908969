import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './collection.css';

const limit = 15;

export default function AllCollection(){
  let [total, setTotal] = useState(0);
  let [page, setPage] = useState(0);
  let [collections, setCollections] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getTotal();
    getCollections();
  }, []);

  const getTotal = () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/collections/total`).then(r => r.json()).then(res => setTotal(res.total));
  }

  const getCollections = () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/collections/get/${page}/${limit}`).then(r => r.json()).then(r => {
      setCollections(collections.concat(r.collections));
      setPage(page+1);
    })
  }

  return(
    <div className='container mt-5 text-white'>
      <div className='row'>
        <div className='col-12'>
          <h2>All Collections</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4>Total Collections: {total}</h4>
        </div>
      </div>
      <div className='row'>
        {collections.map((item) => {
          return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="card" onClick={() => navigate(`/collection/${item.trimmedname}`)}>
                <img src={item.logo
                  ? `${process.env.REACT_APP_BASE_URL}/${item.logo}`
                  : "https://via.placeholder.com/150"} className="card-img-top" alt="..." />
                <div className="card-body" style={{background: 'linear-gradient(45deg, #D279F5, #422E9B)', borderRadius: '0px 0px 12px 12px'}}>
                  <h5 className="card-title">{item.name}</h5>
                  {/* <p className="card-text">{item.desc}</p> */}
                </div>
              </div>
            </div>
            )
        })}
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          {(page+1) * limit < total ? <button className='show-more-btn' onClick={getCollections}>Show More</button> : <></>}
        </div>
      </div>

    </div>
  );
}
