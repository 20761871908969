import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function CreateModal({createModal, closeModal, status, mintToken, listToken}){
  return(
    <Modal show={createModal} className="create-modal" centered={true} >
            <Modal.Header style={{display: (status === 'Asking to Mint' || status === 'Asking to List') ? 'flex' : 'none', fontSize: '32px'}}>
                {status === 'Asking to Mint' && 'Mint NFT'}
                {status === 'Asking to List' && 'List NFT'}
            </Modal.Header>
            <Modal.Body className="mx-3">
                {status === 'Asking to Mint' ? (
                    <div style={{margin: '20px 0px', fontSize: '22px'}}>Do you want to mint your NFT?</div>
                ) : (<></>)} 
                {status === 'Asking to List' ? (
                    <div style={{margin: '20px 0px', fontSize: '22px'}}>Do you want to list your NFT?</div>
                ) : (<></>)}
                {(status !== 'Asking to List' && status !== 'Asking to Mint') ? (
                    <div className='text-center my-3'>
                        <div className="create-loader"></div>
                        <h4>Status: Minting NFT</h4>
                        <p>Dont close the window until the project is created!</p>                        
                    </div>
                ) : (<></>)}
            </Modal.Body>
            <Modal.Footer style={{display: (status === 'Asking to Mint' || status === 'Asking to List') ? 'flex' : 'none'}}>
                <div className="WalletModal w-100">

                    <div className="btnDiv mintModalBtn mb-1">

                        <Button type="button" className="btn w-100 me-3" onClick={() => {
                            if (status === 'Asking to Mint'){
                                mintToken();
                            }
                            else{
                                listToken();
                            }
                        }}>
                            {status === 'Asking to Mint' && 'Mint Now'}
                            {status === 'Asking to List' && 'List Now'}
                        </Button>
                        <Button onClick={closeModal} type="button" className="btn w-100">Close</Button>
                    </div>
                </div>
            </Modal.Footer>
  </Modal>
  );
}