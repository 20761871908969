import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import web3upd from '../../web3';
import { currencies } from '../../helpers/constants';

export default function CollectibleItemCard({item, collectible_address}){
  const [modal, showModal] = useState(false);

  const navigate = useNavigate();

  return(
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mt-5" style={{paddingRight:'6px',paddingLeft:'6px'}}>

      <div className="card collectiblesCard" onClick={() => {if (parseInt(item.status) !== 0){navigate(`/detail/${item.nft_id}`)}}} id="main-card">
        {/* <div  className="d-flex justify-content-center align-items-center p-2" style={{border:'1px solid lightslategrey',borderRadius:'25px'}}> */}
          <div className="imgDiv">
            <img src={`${process.env.REACT_APP_BASE_URL}/${item?.image_local}`} id="card-img" className="card-img-top w-100" alt="..."></img>
          </div>
        {/* </div> */}
        <div className="card-body" style={{background: 'linear-gradient(45deg, #D279F5, #422E9B)', borderRadius: '0px 0px 12px 12px'}}>
          <div>
          <div className="d-flex justify-content-between">
                <span className="card-title h5 pt-1 text-white" id="card-title-font" style={{wordBreak:'break-word'}}>{item.name}</span>
                {/* <span className="card-title" id="profile-heart-background" style={{height: 'fit-content'}}><i id="heart" className="fas fa-heart" style={{color:"#162e4a"}}></i></span> */}
            </div>
            {(item.minted) ? (
              <>
                {item.status === "0" && ( // not listed
                  <>
                    <p className="mb-1 text-left  text-white" id="bid-color">Status: <span id="blue-color"> Not Listed</span></p>
                    <div className="text-left pt-1" style={{height: '28px'}}>
                        <span className="card-title mb-0  text-white" id="bid-color">Owned by: <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> {item.owner.address.substring(0,5)}...{item.owner.address.substring(38,42)}</span></span>
                    </div>
                  </>
                )}
                {item.status === "1" && ( // on instant buy
                  <>
                    <p className="mb-1 text-left  text-white" id="bid-color">Status: <span id="blue-color"> Available for buying</span></p>
                    {/* <div className="text-left pt-1">
                      <span className="card-title mb-0  text-white" id="bid-color">Owned by: <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> {item.owner.address.substring(0,5)}...{item.owner.address.substring(38,42)}</span></span>
                    </div> */}
                    <div className="text-left pt-1">
                      <span className="card-title mb-0  text-white" id="bid-color">Price: <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> 
                        {item?.price} {currencies[item?.currency].symbol}
                        </span>
                      </span>
                    </div>
                  </>
                )}
                {item.status === "2" && ( // on auction
                  <>
                  <p className="mb-1 text-left  text-white" id="bid-color">Status: <span id="blue-color"> Available for bidding</span></p>
                  {/* <div className="text-left pt-1">
                    <span className="card-title mb-0  text-white" id="bid-color">Owned by: <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> {item.owner.address.substring(0,5)}...{item.owner.address.substring(38,42)}</span></span>
                  </div> */}
                  <div className="text-left pt-1">
                    <span className="card-title mb-0  text-white" id="bid-color">{'Minimum Bid: '} <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> 
                      {item?.price} {currencies[item?.currency].symbol}
                    </span></span>
                  </div>
                </>
                )}
              </>
            ) : (
              <>
                {/* <p className="mb-1 text-left  text-white" id="bid-color">Status: <span id="blue-color"> Not Minted</span></p> */}
                <div className="text-left pt-1" style={{height: '28px'}}>
                  <span className="card-title mb-0  text-white" id="bid-color">Owned by: <span id="blue-color" style={{cursor: 'pointer', position: 'absolute'}}> {item.owner.address.substring(0,5)}...{item.owner.address.substring(38,42)}</span></span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}