
import { Link } from 'react-router-dom';
import logo from '../images/winIco.png';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import instagram from '../images/instagram.svg';
import whatsapp from '../images/whatsapp.svg';
import youtube from '../images/youtube.svg';
import linkedin from '../images/linkedin.svg';
import snapchat from '../images/snapchat.svg';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

export const Footer = () => {
  const [categories, setCategories] = useState([]);
  const getCategories = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`).then(r => r.json())
    .then(r => {
      if (r.success){
        setCategories(r.categories);
      }
    })
  }
  useEffect(() => {
    getCategories();
  }, []);
  const subscribeToLetter = () => {
    let email = document.getElementById('subs-email').value;
    if (email){
      fetch(`${process.env.REACT_APP_BASE_URL}/newsletters/add/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.success){
          toast(data.msg, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert();
        }
        else{
          toast(data.msg, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert(data.msg);
        }
      })
    }
    else{
      toast('Please enter a valid email address', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      // alert('Please enter a valid email address');
    }
  }

    return(
      <footer>
        <div className="miniFooter">
          <div className="container">
            <div className="row jc-sb ai-fe">
              <div className="col-lg-6">
                <div className="colOne">
                  <p>Stay in the loop</p>
                  <p>Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for navigating WinWinWin.</p>
                  <div className="mail">
                    <input type="text" placeholder='Your Email Address' id="subs-email" />
                    <button onClick={subscribeToLetter} className='themeBtn'>Signup</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="colTwo">
                  <p>Join the community</p>
                  <div className="socialMain">
                    <a href="#" target="_blank"><img src={facebook} alt="" /></a>
                    <a href="#" target="_blank"><img src={twitter} alt="" /></a>
                    <a href="#" target="_blank"><img src={instagram} alt="" /></a>
                    <a href="#" target="_blank"><img src={whatsapp} alt="" /></a>
                    <a href="#" target="_blank"><img src={youtube} alt="" /></a>
                    <a href="#" target="_blank"><img src={linkedin} alt="" /></a>
                    <a href="#" target="_blank"><img src={snapchat} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="container">
          <div className="row pt-5">
            <div className=" col-md-6 col-lg-4">
              <div className="">
                <div className="logoMain">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="desc">
                The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital assets.
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg">
              <div className="col1">
                {/* <h6>Marketplace</h6> */}
                <ul>
                  <li><Link to="/">Marketplace</Link></li>
                  <li><Link to="/">All NFTs</Link></li>
                  {categories.map((c, i) => <li key={`footer-cat-${i}`}><Link to="/">{c.name}</Link></li>)}
                  {/* <li><Link to="/">New</Link></li>
                  <li><Link to="/">Art</Link></li>
                  <li><Link to="/">Music</Link></li>
                  <li><Link to="/">Domain Names</Link></li>
                  <li><Link to="/">Virtual Worlds</Link></li>
                  <li><Link to="/">Trading Cards</Link></li>
                  <li><Link to="/">Collectibles</Link></li>
                  <li><Link to="/">Sports</Link></li>
                  <li><Link to="/">Utility</Link></li> */}
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg">
              <div className="col1">
                {/* <h6>My Account</h6> */}
                <ul>
                  <li><a href="#">My Account</a></li>
                  <li><a href="#">My Profile</a></li>
                  <li><a href="#">My Collections</a></li>
                  <li><a href="#">My Favorites</a></li>
                  <li><a href="#">My Account Settings</a></li>
                  <li><a href="#">Stats</a></li>
                  <li><a href="#">Rankings</a></li>
                  <li><a href="#">Activity</a></li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg">
              <div className="col1">
                {/* <h6>Company</h6> */}
                <ul>
                  <li><a href="#" target='_blank'>Resources</a></li>
                  <li><a href="#" target='_blank'>Help Center</a></li>
                  <li><a href="#" target='_blank'>Platform Status</a></li>
                  <li><a href="#" target='_blank'>Partners</a></li>
                  <li><a href="#" target='_blank'>Suggestions</a></li>
                  <li><a href="#" target='_blank'>Discord Community</a></li>
                  <li><a href="#" target='_blank'>Blog</a></li>
                  <li><a href="#" target='_blank'>Docs</a></li>
                  <li><a href="#" target='_blank'>Newsletter</a></li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg">
              <div className="col1">
                {/* <h6>Company</h6> */}
                <ul>
                  <li><a href="#" target='_blank'>Company</a></li>
                  <li><a href="#" target='_blank'>About</a></li>
                  <li><a href="#" target='_blank'>Careers</a></li>
                </ul>
              </div>
            </div>
          </div>
         
          <div className="copyRight">
            <div className="copy">
            © 2022 Winwinwin Token Project
            </div>
            <div className="policyF d-flex">
              <div className="policy me-3"><Link to="/privacy"> Privacy Policy</Link></div>
              <div className="policy"><Link to="/privacy"> Terms of Service</Link></div>
            </div>
          </div>
        </div>
      </footer>
    )
}