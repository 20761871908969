import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
  Navigate,
  useLocation,
} from "react-router-dom";

import Slider from "react-slick";
import web3 from './web3';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery.min.js";
// slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavbarCustom } from "./component/navbarCustom";
import { Footer } from "./component/footer";
import Scroll from './scroll'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MarketPlace } from "./component/explore/marketplace";
import { ProductDetail } from "./component/productDetail/productDetail";
import { WelcomePage } from "./component/welcomePage/welcomePage";
import { CreatePage } from "./component/createPage/createPage";
import { ProfilePage } from "./component/profilePage/profilePage";
import { TradeHistory } from "./component/tradingHistory/tradingHistory";
import Web3 from "web3";
import { useDispatch } from "react-redux";
import { loginUser, getStatus } from "./redux/ActionCreators";
import CreateCollection from "./component/collectionCreate/createCollection";
import AllCollection from "./component/collection/collections";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CollectionDetail from "./component/collection/collectionDetail";
import AdminPage from "./component/adminpanel/admin";

const checkIfUserExists = (address) => fetch(`${process.env.REACT_APP_BASE_URL}/users/exists/${address}`).then(r => r.json())
const verifySignature = (signature, address) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/users/login`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      signature,
      address
    })
  }).then(r => r.json())
}

const jwtValid = (token, address) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/users/check-jwt/${address}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${token}`
    }
  }).then(r => {
    console.log(r);
    if (r.ok){
      return r.json();
    }
    else{
      return {success: false};
    }
  });
}

//  Create WalletConnect Provider
const providerBsc = new WalletConnectProvider({
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
    qrcode: true,
    pollingInterval: 12000,
  },
  chainId: parseInt(process.env.REACT_APP_CHAIN_ID)
});

let wallet = {'w': 'walletconnect', 'm': 'metamask'};

function Main() {
  const dispatch = useDispatch();

  const connectMetamask = async (type) => {
    let accs = [];
    console.log(type);
    if (type === 'w'){
      try{
        window.localStorage.removeItem('walletconnect');
        await providerBsc.enable();
        const result = await providerBsc.request("eth_chainId");
        console.log(result);
        if (parseInt(result) === parseInt(process.env.REACT_APP_CHAIN_ID)){
          const web33 = new Web3(providerBsc);
          web3.web3 = web33;
          accs = await web33.eth.getAccounts();
          console.log(result);
        }
        else{
          toast('Wrong Chain. Expected: ' + parseInt(process.env.REACT_APP_CHAIN_ID) + ' Got: ' + parseInt(result), {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });

          // alert('Wrong Chain. Expected: ' + parseInt(process.env.REACT_APP_CHAIN_ID) + ' Got: ' + parseInt(result));
        }
      }
      catch(e){
        console.log(e);
        
        toast('Login failed', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        // alert('Login failed');
      }
    }
    else if (type === 'm'){
      if (!window.ethereum) {
        toast('Install metamask or use Wallet Connect', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        // alert("Install metamask or use Wallet Connect");
      } 
      else if (parseInt(window.ethereum.chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) { 
        try{
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x61' }],
          });
          accs = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          web3.web3 = new Web3(window.ethereum);
        }
        catch(e){
          toast('Login Failed', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Login Failed')
        }
      }
      else {
        accs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        web3.web3 = new Web3(window.ethereum);
      }
    }
    if (accs.length > 0) {
      console.log(accs);
      let ad = accs[0].toLowerCase();
      let noToken = true;
      let r = await checkIfUserExists(ad);
      
      if (localStorage.getItem(ad.toLowerCase())){
        let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
        console.log(valid);
        if (valid.success){
          valid.user.token = localStorage.getItem(ad.toLowerCase());
          localStorage.setItem(wallet[type], 1);
          dispatch(loginUser(valid.user));
          noToken = false;
        }
        else{
          localStorage.removeItem(ad);
        }
      }
      if (noToken){
        let signature, signed = true;
        try{
          signature = await web3.web3.eth.personal.sign(
            `I am signing my one-time nonce: ${r.user.nonce}`,
            accs[0],
            ''
          );  
        }
        catch(e){
          signed = false;
          toast('Need to sign signature to continue', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Need to sign signature to continue.');
        }
        if (signed){
          let res = await verifySignature(signature, ad);
          if (res.success){
            r.user.token = res.token;
            localStorage.setItem(wallet[type], 1);
            dispatch(loginUser(r.user));
          }
          else{
            toast('Invalid Signature', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('Invalid Signature');
          }
        } 
      }
      return {success: true}
    } else {
      toast('Please connect to MetaMask.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      // alert("Please connect to MetaMask.");
      return {success: false};
    }  
  };

  useEffect(() => {
    if (parseInt(localStorage.getItem('metamask')) === 1){
      if (window.ethereum) {
        connectMetamask('m');
      } else {
        window.addEventListener("ethereum#initialized", () => connectMetamask('m'), {
          once: true,
        });
        setTimeout(() => connectMetamask('m'), 3000);
      }
    }
    dispatch(getStatus());
  }, []);

  return (
    <>
      <Scroll></Scroll>
      <NavbarCustom connectMetamask={connectMetamask} />
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        {/* <Route path="/welcome" element={<WelcomePage />} /> */}
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/create" element={<CreatePage />} />
        <Route path="/detail/:token_id" element={<ProductDetail />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/profile/:address" element={<ProfilePage />} />
        <Route path="/trade-history" element={<TradeHistory />} />
        <Route path="/create-collection" element={<CreateCollection />} />
        <Route path="/collections" element={<AllCollection />} />
        <Route path="/collection/:trimmedname" element={<CollectionDetail />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default Main;
