import React, { useEffect, useState } from "react";
import CollectibleItemCard from "./collectibleCard";

export default function SaleTab({col_id}){
  let [saleItems, setSaleItems] = useState([]);

  useEffect(() => {
    if (col_id){
      fetch(`${process.env.REACT_APP_BASE_URL}/collections/sale-items/${col_id}`)
      .then((res) => res.json()).then(r => setSaleItems(r.items))
    }
  }, [col_id]);
  return(
    <>
    {saleItems.map((item,index)=>{
      return(
        <CollectibleItemCard key={`s-itm-${index}`} item={item} />
      )
    })}
    </>
  );
}