import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import './blockedUserModal.css';

const BlockedUserModal = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal className="item-hidden-modal" isOpen={true} centered>
        <ModalHeader className="item-hide-header-modal">
          Item Blocked
        </ModalHeader>
        <ModalBody>
          DISCLAIMER : This item has been banned, to learn more about this contact support.
        </ModalBody>
        <div classNam="d-flex justify-content-center">
          <div className="text-center">
            <p className="mb-2 mb-sm-3">Explore Marketplace.</p>
            <Button
              className="understand-btn"
              color="secondary"
              onClick={() => navigate('/marketplace')}
            >
              Go to Marketplace
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default BlockedUserModal;