import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, InputGroup, InputGroupText, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BinanceLogo from '../images/binance20by20.png'
import MarketPlaceABI from '../ethereum/WinwinMarketplaceV1.json';
import web3 from '../web3';
import { useNavigate } from 'react-router-dom';
import BN from 'bn.js';
import ERC721ABI from '../ethereum/ERC721.json';
import { currencies } from '../helpers/constants';
import { toast } from 'react-toastify';
import { listingAllowed } from '../helpers/helpers';

export default function ListingModal({item, show, setShow}){
  const [listingStatus, setListingStatus] = useState('0');
  const [step, setStep] = useState(1);
  const [bnbAmt, setBnbAmt] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('bnb');

  const authedUser = useSelector(state => state.authedUser);
  const appStatus = useSelector(state => state.status);

  const navigate = useNavigate();


  const resett = () => setShow(false);
  const toggle = () => setShow(!show);
  
  const isApproved = async (token_id, collection_address) => {
    console.log(collection_address);
    let minterNFT = new web3.web3.eth.Contract(ERC721ABI, collection_address);
    console.log(minterNFT);
    const approved = await minterNFT.methods.isApprovedForAll(authedUser.authedUser.address, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS).call();
    console.log(approved);
    if (!approved){
      await minterNFT.methods.setApprovalForAll(process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS, true).send({from: authedUser.authedUser.address});
      console.log('ajsdn');
      return;
    }
  }
  const listNft = async () => {
    if (appStatus){
      let listAccess = await listingAllowed(authedUser.authedUser.address.toLowerCase());
      if (listAccess){
        if (authedUser.authedUser._id === item.owner){
          try{
            setStep(3);
            setUpdateLoading(true);
            await isApproved(item.nft_id, item?.collection_id?.collection_address ? item?.collection_id?.collection_address : process.env.REACT_APP_MINTER_CONTRACT_ADDRESS);
            let aa = new web3.web3.eth.Contract(MarketPlaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
            console.log(bnbAmt);
            const nftPriceFromatted = web3.web3.utils.toWei(bnbAmt);
            console.log(bnbAmt, nftPriceFromatted);
            console.log(parseInt(item.nft_id), item?.collection_id?.collection_address ? item?.collection_id?.collection_address : process.env.REACT_APP_MINTER_CONTRACT_ADDRESS, nftPriceFromatted, listingStatus === '2', currencies[paymentMethod].address);
            let res = await aa.methods.addItemToMarket(parseInt(item.nft_id), item?.collection_id?.collection_address ? item?.collection_id?.collection_address : process.env.REACT_APP_MINTER_CONTRACT_ADDRESS, nftPriceFromatted, listingStatus === '2', currencies[paymentMethod].address).send({
              from: authedUser.authedUser.address
            });
            console.log(res);
            console.log(res.events.ItemAdded.returnValues[0]);
            fetch(`${process.env.REACT_APP_BASE_URL}/items/listed`, {
              method: 'PUT',
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(authedUser.authedUser.address.toLowerCase()),
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                item_id: item._id,
                price: bnbAmt,
                status: listingStatus,
                marketplace_id: res.events.ItemAdded.returnValues[0],
                txHash: res.txHash,
                currency: paymentMethod
              })
            }).then(r => r.json()).then(res => {
              console.log(res);
              if (res.success){
                navigate(`/detail/${item?.nft_id}`);
              }
              else{
                toast('Failed', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  theme: 'dark'
                });
                // alert('Failed');
                setShow(false);
              }
            });
          }
          catch(e){
            console.log(e);
            toast('Failed', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('Failed');
            setShow(false);
            setStep(1);
            setUpdateLoading(false);
          }
        }
        else{
          toast('Youre not owner', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Youre not owner');
        }
      }
      else{
        toast('This feature is blocked. Contact admin', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });    
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  return(
    <Modal show={show} className="listing-modal" centered={true}>
        <Modal.Header style={{textAlign: 'center', fontSize: '32px', display: updateLoading ? 'none' : 'flex'}} className="py-2" toggle={toggle}>
          <i className={step === 1 ? '' : "fal fa-arrow-left mr-3 text-secondary"} onClick={()=> setStep(1)} style={{fontSize:'16px',cursor:'pointer'}}></i> List Your Token - Step {step} <span></span>
        </Modal.Header>
        <ModalBody>
          <div className='p-3'>
            {step === 1 && (
            <>
              <div className="list-status-btn" style={{fontSize: '20px'}} onClick={() => {setListingStatus('2'); setStep(2)}}>
                List for - Auction
              </div>
              <div className="list-status-btn" style={{fontSize: '20px'}} onClick={() => {setListingStatus('1');  setStep(2)}}>
                List for - Instant Buy
              </div>
              {/* <div>
                <small>Royalty Percent: {collectionItem?.royalty_percent}</small>
              </div> */}
            </>        
            )}
            {step === 2 && (
            <>
                                    
            <label style={{textAlign: 'left', fontSize: '18px'}}>Enter Amount:</label>
            <InputGroup className="mb-3">
              <Form.Control
                value={bnbAmt} onChange={(e) => setBnbAmt(e.target.value)}
                placeholder="BNB Amount"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <img src={currencies[paymentMethod].icon} />
              </InputGroup.Text>
            </InputGroup>

            <div>
                <label className="label headingsLab" style={{fontSize: '18px'}}>Payment Methods</label>
                <div className="modeBtnMain w-100">
                  <select className="custom-select form-control custom-select-lg mb-3" style={{ borderRadius: "10px" }} onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option value="bnb">BNB</option>
                    <option value="win">WIN</option>
                  </select>
                </div>
              </div>
            </>        
            )}
            {updateLoading && (
              <div className='text-center'>
                <div className="create-loader"></div>
                <h4>Status: Listing your nft</h4>
                <p>Dont close the window until the item is listed is created!</p>
              </div>
            )}
          </div>
        </ModalBody>

        <Modal.Footer style={updateLoading ? {display: 'none'} : {}}>
        <div className="WalletModal w-100">
          <div className="btnDiv mintModalBtn mb-1"> 
            {listingStatus !== '0' && (
              <Button color="secondary" className='w-100 me-3'  style={{fontSize: '20px'}} onClick={() => listNft()}>List NFT</Button>
            )}
            <Button color="secondary" className='w-100' style={{fontSize: '20px'}} onClick={() => resett()}>Cancel</Button>
          </div>
        </div>
        </Modal.Footer>
    </Modal>
  );
}