import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ImgPlaceholder from "../../images/galleryIcon.png"
import EditCategoryModal from './editCatModal';
import BlockApp from './blockApp';
// import FeaturedNFTs from './featuredNFTs';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
// import FeaturedCollection from './featuredCollection';
import logout from "../../images/logout.png";
import './admin.css'
// import BlueTick from './blueTick';
import BanNFTs from './banNFTs';
import DeleteNFTS from './deleteNfts';

import BlockedUsers from './blockUsers';
import UpdateCollections from './updateCollections';
// import PriceLinkUpdate from './priceLinkUpdate';

export default function AdminPage(){
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [editModal, showEditModal] = useState(false);
    const [eCategory, setECategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [listedItems, setListedItems] = useState([]);
    const dispatch = useDispatch();
    const [jwt, setJWT] = useState(localStorage.getItem('token'));
    const [activeTab, setActiveTab] = useState('3');

    const banNFT = (token_id) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/items/add-to-banned`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({token_id})
        }).then(r => r.json())
        .then(res => {
            if (res.success){
                setListedItems(listedItems.map(itm => {
                    if (itm.nft_id === token_id){
                        return {...itm, banned: true }
                    }
                    else{
                        return itm
                    }
                }));
            }
            else{
                alert(res.msg);
            }
        });
    }
    const unBanNFT = (id) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/items/remove-from-banned`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({item_id: id})
        }).then(r => r.json())
        .then(res => {
            if (res.success){
                setListedItems(listedItems.map(itm => {
                    if (itm._id === id){
                        return {...itm, banned: false };
                    }
                    else{
                        return itm;
                    }
                }));
            }
            else{
              alert(res.msg);
            }
        })      
    }

    useEffect(() => {
        if (localStorage.getItem('token')){
            fetch(`${process.env.REACT_APP_BASE_URL}/items/listings/requests`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + jwt,
                }
            })
            .then(r => {
                console.log(r);
                if (r.ok){
                    return r.json();
                }
                else{
                    // localStorage.removeItem('token');
                    return {success: false};
                }
            })
            .then(res => {
                console.log(res);
                if (res.success){
                    setItems(res.data);
                }
            });
            fetch(`${process.env.REACT_APP_BASE_URL}/items/listings`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + jwt,
                }
            })
            .then(r => {
                console.log(r);
                if (r.ok){
                    return r.json();
                }
                else{
                    // localStorage.removeItem('token');
                    return {success: false};
                }
            })
            .then(res => {
                console.log(res);
                if (res.success){
                    setListedItems(res.data);
                }
            });
            fetch(`${process.env.REACT_APP_BASE_URL}/categories`)
            .then(r => r.json())
            .then(res => {
                console.log(res);
                if (res.success){
                    setCategories(res.categories);
                }
            })
        }
        else{

        }
    }, [jwt]);

    const deleteCat = (category) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/categories/delete/${category.name}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then(r => r.json()).then(r => {
          if (r.success){
            setCategories(categories.filter(c => c.name !== category.name));
          }
          else{
            alert(r.status)
          }
        });
    }  
    const fileChangeHandler = (e) => {
        if (e.target.files.length > 0){
            // console.log(e.target.files[0])
            setImage(e.target.files[0]);
        }
    }
    const updateCategory = (prev_name, new_categ) => {
        setCategories(categories.map(c => {
          if (c.name === prev_name){
            return {...c, name: new_categ.name, img: new_categ.img};
          }
          else{
            return c;
          }
        }))
    }
    const approveListing = async (item_id) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/items/listings/approve?item_id=${item_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwt,
            }
        }).then(r => r.json())
        .then(r => {
            if (r.success){
                setItems(items.filter(i => i._id !== item_id));
            }
            else{
                toast(r.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: 'dark'
                });        
            }
        });
    }
    const rejectListing = async (item_id) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/items/listings/reject?item_id=${item_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwt,
            }
        }).then(r => r.json())
        .then(r => {
            if (r.success){
                setItems(items.map(i => {
                    if (i._id === r.item._id){
                        return r.item;
                    }
                    else{
                        return i;
                    }
                }))
            }
            else{
                toast(r.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: 'dark'
                });        
            }
        });        
    }

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const adminLogin = () => {
        let uname = document.getElementById('adminid').value;
        let pwd = document.getElementById('adminpwd').value;
        if (uname && pwd){
            var myHeaders = new Headers();
            // myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");
            var bdy = {'username': uname, 'password': pwd};
            // console.log(bdy);
            var raw = JSON.stringify(bdy);
    
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/users/authenticate/admin`, requestOptions)
            .then(res => {console.log(res);return res.json();})
            .then(r => {
                console.log(r);
                if (r.success){
                    localStorage.setItem('token', r.token);
                    setJWT(r.token)
                }
                else{
                    alert('Failed to login');
                }
            })
        }
        else{
            alert('Enter username and password');
        }
    }
    const createCategory = async () => {
        if (name && image){
            let a = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/exist/${name}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).then(r => r.json());
            // console.log(a);
            if (a.total > 0){
                alert('Already Exists!');
            }
            else{
                var myHeadersBoth = new Headers();
                myHeadersBoth.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
                var formdataBoth = new FormData();
                formdataBoth.append("categImg", image, image.name);
                formdataBoth.append("name", name);

                fetch(`${process.env.REACT_APP_BASE_URL}/categories/create`, {
                    method: 'POST',
                    headers: myHeadersBoth,
                    body: formdataBoth
                }).then(r => r.json()).then((r) => {
                    if (r.success){
                        alert('Successfully created');
                        setCategories(categories.concat([r.category]));
                    }
                })
            }    
        }
        else{
            alert('Please enter name and category image!');
        }
    }

    const logoutAdmin = () => {
        localStorage.removeItem('admin');
        setJWT('');
    }

    if (jwt){
        return(
            <>
                <div>
                  <hr style={{
                      backgroundImage: 'linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%)',
                      height: '2px'
                      }}
                    />
                    <div className="row mx-0">
                        <div className="col-12" style={{display: 'flex'}}>
                            <div onClick={logoutAdmin} style={{marginLeft: 'auto', marginRight: '20px'}} className={"logoutIcon"}>
                                <img src={logout} />
                                <span>Logout</span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <Nav tabs className="flex-md-column border-0">
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='3' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '3' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('3'); }}
                                    >
                                        Approve NFTs
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='4' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '4' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('4'); }}
                                    >
                                        Featured NFTs
                                    </NavLink>
                                </NavItem>
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='5' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '5' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('5'); }}
                                    >
                                        Blue Tick
                                    </NavLink>
                                </NavItem>*/}
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='6' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '6' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('6'); }}
                                    >
                                        Ban NFTs
                                    </NavLink>
                                </NavItem>
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='7' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '7' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('7'); }}
                                    >
                                        Block App
                                    </NavLink>
                                </NavItem>
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab === '8' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '8' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('8'); }}
                                    >
                                        Blocked Users
                                    </NavLink>
                                </NavItem>
                                <NavItem className="mt-2">
                                  <NavLink
                                      style={{color: 'white', cursor: 'pointer',background:activeTab === '9' && 'radial-gradient(#204169 , #102135)'}}
                                      className={`${activeTab === '9' ? 'active' : ''},text-white settings-tab`}
                                      onClick={() => { toggle('9'); }}
                                  >
                                      Category
                                  </NavLink>
                                </NavItem> 
                                
                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='10' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '10' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('10'); }}
                                    >
                                        Delete NFTs
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        style={{color: 'white', cursor: 'pointer',background:activeTab ==='11' && 'radial-gradient(#204169 , #102135)'}}
                                        className={`${activeTab === '11' ? 'active' : ''},text-white settings-tab`}
                                        onClick={() => { toggle('11'); }}
                                    >
                                        Edit/Delete Collections
                                    </NavLink>
                                </NavItem>                                
                            </Nav>
                        </div>


                        <div className="col-12 col-md-9">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="3">
                                    <div className="container mt-3">   
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="col-12 text-white">
                                                <div style={{fontFamily: 'Gotham-Font-Navbar', fontWeight: '300', fontSize: '32px',marginBottom: '10px'}} className="text-center">Approve NFTs for Listing</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="container"> 
                                                <table className="table admin-category-table">
                                                    <thead>
                                                        <tr className="text-center text-white">
                                                            <td scope="col" className="p-3" id="wins">NFT NAME</td>
                                                            <td scope="col" className="p-3" id="draws">Approve</td>
                                                            <td scope="col" className="p-3" id="losses">Disapprove</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody> 
                                                        {items.filter(c => c.approvalRequested !== "2").map((c,ind)=>{
                                                            return(
                                                                <tr className="text-center text-white" style={{borderBottom:'1px solid white'}}>
                                                                    <td className="p-3"  scope="row" onClick={() => window.open(`${process.env.REACT_APP_FRONTEND_BASE_URL}/detail/${c.nft_id}`, "_blank")} style={{cursor: 'pointer'}}>
                                                                        <img style={{height: '31px', marginRight: '5px'}} src={`${process.env.REACT_APP_BASE_URL}/${c.image_local}`} /> {c.name}
                                                                    </td>
                                                                    <td className="p-3" ><button className="add-category-btn" onClick={() => approveListing(c._id)} style={{cursor:'pointer',paddingLeft:'20px'}}>Approve</button></td>
                                                                    <td className="p-3" >{c.approvalRequested === "2" ? 'Already Rejected' : <button className="add-category-btn" onClick={() => rejectListing(c._id)} style={{cursor:'pointer',paddingLeft:'20px'}}>Reject</button>} </td>                                        
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="col-12 text-white">
                                                <div style={{fontFamily: 'Gotham-Font-Navbar', fontWeight: '300', fontSize: '32px',marginBottom: '10px'}} className="text-center">Rejected NFTs</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="container"> 
                                                <table className="table admin-category-table">
                                                    <thead>
                                                        <tr className="text-center text-white">
                                                            <td scope="col" className="p-3" id="wins">NFT NAME</td>
                                                            <td scope="col" className="p-3" id="draws">Approve</td>
                                                            <td scope="col" className="p-3" id="losses">Disapprove</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody> 
                                                        {items.filter(c => c.approvalRequested === "2").map((c,ind)=>{
                                                            return(
                                                                <tr className="text-center text-white" style={{borderBottom:'1px solid white'}}>
                                                                    <td className="p-3"  scope="row" onClick={() => window.open(`${process.env.REACT_APP_FRONTEND_BASE_URL}/detail/${c.nft_id}`, "_blank")} style={{cursor: 'pointer'}}>
                                                                        <img style={{height: '31px', marginRight: '5px'}} src={`${process.env.REACT_APP_BASE_URL}/${c.image_local}`} /> {c.name}
                                                                    </td>
                                                                    <td className="p-3" ><button className="add-category-btn" onClick={() => approveListing(c._id)} style={{cursor:'pointer',paddingLeft:'20px'}}>Approve</button></td>
                                                                    <td className="p-3" >{c.approvalRequested === "2" ? 'Already Rejected' : <button className="add-category-btn" onClick={() => rejectListing(c._id)} style={{cursor:'pointer',paddingLeft:'20px'}}>Reject</button>} </td>                                        
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="col-12 text-white">
                                                <div style={{fontFamily: 'Gotham-Font-Navbar', fontWeight: '300', fontSize: '32px',marginBottom: '10px'}} className="text-center">Listed NFTs</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="container"> 
                                                <table className="table admin-category-table">
                                                    <thead>
                                                        <tr className="text-center text-white">
                                                            <td scope="col" className="p-3" id="wins">NFT NAME</td>
                                                            <td scope="col" className="p-3" id="draws">Owner</td>
                                                            <td scope="col" className="p-3" id="losses">Price</td>
                                                            <td scope="col" className="p-3" id="losses">Ban/Unban NFT</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody> 
                                                        {listedItems.map((c,ind)=>{
                                                            return(
                                                                <tr className="text-center text-white" style={{borderBottom:'1px solid white'}}>
                                                                    <td className="p-3"  scope="row" onClick={() => window.open(`${process.env.REACT_APP_FRONTEND_BASE_URL}/detail/${c.nft_id}`, "_blank")} style={{cursor: 'pointer'}}>
                                                                        <img style={{height: '31px', marginRight: '5px'}} src={`${process.env.REACT_APP_BASE_URL}/${c.image_local}`} /> {c.name}
                                                                    </td>
                                                                    <td className="p-3" >{c.owner?.address}</td>
                                                                    <td className="p-3" >{c.price} </td>
                                                                    <td className="p-3" ><button className="add-category-btn" onClick={() => {
                                                                        if (c?.banned){
                                                                            unBanNFT(c?._id);
                                                                        }
                                                                        else{
                                                                            banNFT(c?.nft_id);
                                                                        }
                                                                    }} style={{cursor:'pointer',paddingLeft:'20px'}}>{c?.banned ? 'Unban' : 'Ban'}</button></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <EditCategoryModal show={editModal} setShow={showEditModal} categories={categories} updateCategory={updateCategory} category={eCategory} setCategory={setECategory} /> */}
                                </TabPane>
                                
                                <TabPane tabId="6">
                                    <BanNFTs />
                                </TabPane>
                                
                                <TabPane tabId="7">
                                    <BlockApp />
                                </TabPane>
                                
                                <TabPane tabId="8">
                                  <BlockedUsers />
                                </TabPane>
                                <TabPane tabId="9">
                                    <div className="container create-nft-form mt-4" style={{color: 'white'}}>
                                        <h3 className="text-center" style={{fontFamily: 'Gotham-Font-Navbar', fontWeight: '400', fontSize: '32px'}}>Add a New Category</h3>
                                        <div className="row">
                                            <div className="col-12" >
                                                <h6 style={{fontWeight: '400'}} className="mt-3">Add Category</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-3">
                                        {/* <img src={Empirelogo} alt="..." id="connect-bg-logo" style={{top: '40%'}}></img> */}
                                        <div className="row"> 
                                            <div className="col-12 col-sm-6 " style={{color: 'white'}}>
                                                <label htmlFor="title">Name</label> <br/>
                                                <input type="text" className="text-white" placeholder="Name of Category" id="title" onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3">
                                            <div className="col-12 text-white">
                                                <div style={{fontSize: '18px', marginBottom: '10px'}} >Category Image</div>
                                            </div>
                                            <div className="col-12 text-white">
                                                <input type="file" style={{display: 'none'}} id="input-categ-file" onChange={fileChangeHandler} accept="image/*" />
                                            </div>
                                            <div className="col-6 col-md-5 mt-3">
                                                    {image ? (
                                                        <div>
                                                            <img onClick={() => document.getElementById("input-categ-file").click()} src={URL.createObjectURL(image)} style={{height: '9em', width: '9em', borderRadius: '50%', cursor: 'pointer'}} />
                                                        </div>
                                                    ) : (
                                                        <div id="categ-upload" onClick={() => document.getElementById("input-categ-file").click()}>
                                                            <img src={ImgPlaceholder} />
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3">
                                            <div className="col-12 col-sm-6">
                                                <button onClick={() => createCategory()} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Add Category</button>
                                            </div>
                                        </div>
                                                
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="col-12 text-white">
                                                <div style={{fontFamily: 'Gotham-Font-Navbar', fontWeight: '300', fontSize: '32px',marginBottom: '10px'}} className="text-center">Edit Category</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3" style={{color: 'white'}}>
                                            <div className="container"> 
                                                <table className="table admin-category-table">
                                                    <thead>
                                                        <tr className="text-center text-white">
                                                            <td scope="col" className="p-3" id="wins">CATEGORY NAME</td>
                                                            <td scope="col" className="p-3" id="draws">EDIT</td>
                                                            <td scope="col" className="p-3" id="losses">DELETE</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody> 
                                                        {categories.map((c,ind)=>{
                                                            return(
                                                                <tr className="text-center text-white" style={{borderBottom:'1px solid white'}}>
                                                                    <td className="p-3"  scope="row">{c.img && <img style={{borderRadius: '50%', height: '20px', width: '20px'}} src={c.img} />} {c.name}</td>
                                                                    <td className="p-3" ><button className="add-category-btn" onClick={() => {setECategory(c); showEditModal(true)}}  style={{cursor:'pointer',paddingLeft:'20px'}}>Edit</button></td>
                                                                    <td className="p-3" ><button className="add-category-btn" onClick={() => deleteCat(c)}  style={{cursor:'pointer',paddingLeft:'20px'}}>Delete</button></td>                                        
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <EditCategoryModal show={editModal} setShow={showEditModal} categories={categories} updateCategory={updateCategory} category={eCategory} setCategory={setECategory} />
                                </TabPane>
                                
                                <TabPane tabId="10">
                                  <DeleteNFTS />
                                </TabPane>
                                
                                <TabPane tabId="11">
                                  <UpdateCollections />
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                    <hr 
                        style={{
                            backgroundImage: 'linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%)',
                            height: '2px',
                            marginTop:'40px'
                        }} 
                    />
                </div>     
            </>
        );
    }
    else{
        return(
            <div>
                <hr 
                style={{
                    backgroundImage: 'linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%)',
                    height: '2px',
                    marginTop:'40px',
                    marginBottom:'40px'
                }} 
                />
                <div style={{textAlign: 'center', color: 'white'}}>
                    <label htmlFor="adminid">Username : </label><br/>
                    <input type="text" id="adminid" placeholder="Enter Username"/>
                    <div style={{marginBottom: '20px'}}/>
                    <label htmlFor="adminpwd">Password : </label><br/>
                    <input type="password" id="adminpwd"  placeholder="Enter Password"/>
                    <div style={{marginBottom: '20px'}}/>
                    <button className="create-btn" onClick={() => adminLogin()}>Login</button>
                </div>
                <hr 
                    style={{
                        backgroundImage: 'linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%)',
                        height: '2px',
                        marginTop:'40px'
                    }} 
                    />
            </div>
        )
    }
}