import React from 'react';
import { useNavigate } from 'react-router-dom';
import profile_placeholder from '../../images/profile-placeholder250x250.jpg'

export default function FollowerCard({item, collectible_address}){
  const navigate = useNavigate();

  return(
    <div className="col-12 col-md-6 col-lg-3 mt-3" >
      <div className="card" onClick={() => {navigate(`/profile/${item.address}`)}} id="main-card">
        <img src={item.profilepic ? `${process.env.REACT_APP_BASE_URL}/${item.profilepic}` : profile_placeholder} className="card-img-top" alt="..." />
        <div className="card-body" style={{background: 'linear-gradient(45deg, #D279F5, #422E9B)', borderRadius: '0px 0px 12px 12px'}}>
          <h5 className="card-title">{item.displayName ? item.displayName : `${item.address.substring(0,5)}...${item.address.substring(38,42)}`}</h5>
          {/* <p className="card-text">{item.desc}</p> */}
        </div>
      </div>
    </div>
  );
}