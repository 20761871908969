import React from "react";
import "./tradeHistory.css"
import bicon from '../../images/bathicon.png';
import artWork from '../../images/artWork.png';
import blueEffect from '../../images/blueEffect.png';

export function TradeHistory(params) {
    return (
        <>
        <div className="tradeHistoryMain">
        <img className="effect1" src={blueEffect} alt="bluePinkEffect" />
        <img className="effect2" src={blueEffect} alt="bluePinkEffect" />

            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="filterSec">
                            <div className="filter mb-3">
                                filter
                            </div>
                            <div className="filMain">
                                <div className="filter">
                                    <input className="me-2" type="checkbox" name="filter" value="listing" /> 
                                    Listings
                                </div>
                                <div className="filter">
                                    <input className="me-2" type="checkbox" name="filter" value="sales" /> 
                                    Sales
                                </div>
                                <div className="filter">
                                    <input className="me-2" type="checkbox" name="filter" value="bids" /> 
                                    Bids
                                </div>
                                <div className="filter">
                                    <input className="me-2" type="checkbox" name="filter" value="transfer" /> 
                                    Transfers
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <h1>Trading History</h1>

                        <div className="tblcontent-cont">
                            <div className="table-responsive">
                            <table className="table mb-0">
                            <thead>
                                <tr>
                                <td scope="col">Date</td>
                                <td scope="col">Event</td>
                                <td scope="col">Item</td>
                                <td scope="col">Price</td>
                                <td scope="col">From</td>
                                <td scope="col">To</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><div className="w-mc">18 Aug 2021</div></td>
                                <td>Bid</td>
                                <td>
                                    <div className="itemDiv">
                                        <div className="logo">
                                            <img className="img-fluid" src={artWork} alt="logo" />
                                        </div>
                                        <div className="price">
                                            <div className="bath">Product Name</div>
                                            <div className="dollar">Collection Name</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="profileDiv">
                                        <div className="logo">
                                            <img className="img-fluid" src={bicon} alt="logo" />
                                        </div>
                                        <div className="price">
                                            <div className="bath">999 BATH</div>
                                            <div className="dollar">~ $999</div>
                                        </div>
                                    </div>
                                </td>
                                <td>Ox2345vjbdf</td>
                                <td>-</td>
                                </tr>
                                <tr>
                                <td>18 Aug 2021</td>
                                <td>Bid</td>
                                <td>
                                    <div className="itemDiv">
                                        <div className="logo">
                                            <img className="img-fluid" src={artWork} alt="logo" />
                                        </div>
                                        <div className="price">
                                            <div className="bath">Product Name</div>
                                            <div className="dollar">Collection Name</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="profileDiv">
                                    <div className="logo">
                                        <img className="img-fluid" src={bicon} alt="logo" />
                                    </div>
                                    <div className="price">
                                        <div className="bath">999 BATH</div>
                                        <div className="dollar">~ $999</div>
                                    </div>
                                    </div>
                                </td>
                                <td>Ox2345vjbdf</td>
                                <td>-</td>
                                </tr>
                                <tr>
                                <td>18 Aug 2021</td>
                                <td>Bid</td>
                                <td>
                                    <div className="itemDiv">
                                        <div className="logo">
                                            <img className="img-fluid" src={artWork} alt="logo" />
                                        </div>
                                        <div className="price">
                                            <div className="bath">Product Name</div>
                                            <div className="dollar">Collection Name</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="profileDiv">
                                    <div className="logo">
                                        <img className="img-fluid" src={bicon} alt="logo" />
                                    </div>
                                    <div className="price">
                                        <div className="bath">999 BATH</div>
                                        <div className="dollar">~ $999</div>
                                    </div>
                                    </div>
                                </td>
                                <td>Ox2345vjbdf</td>
                                <td>-</td>
                                </tr>
                                
                            
                            </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}