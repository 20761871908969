import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import ImgPlaceholder from '../../images/img-placeholder.png'
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ipfs from '../ipfs'
import './create.css'
import CreateModal from './createModal';
import { Loader } from '../loader';
import { creatingNftAllowed, mintNFT } from '../../helpers/helpers';
import { toast } from 'react-toastify';
import ListingModal from '../listingModal';


const createItemRequest = (name, desc, image, metadata, address, imgType, properties, colid, categ_id) => {
  var myHeaders = new Headers();

  var formdata = new FormData();
  formdata.append("nftImage", image, image.name);
  formdata.append("name", name);
  formdata.append("description", desc);
  // formdata.append("image_ipfs", imageHash);
  // formdata.append("token_uri", jsonHash);
  formdata.append("metadata", JSON.stringify(metadata));
  formdata.append("address", address);
  formdata.append("nftType", imgType);
  formdata.append("properties", JSON.stringify(properties));
  formdata.append("collectionId", colid);
  if (categ_id){
    formdata.append("categoryId", categ_id);
  }

  var requestOptions = {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem(address.toLowerCase())
  },
  body: formdata,
  redirect: 'follow'
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/items/add/${address}`, requestOptions)
  .then(r => r.json())
}

const adminTokenValid = async () => {
  if (localStorage.getItem('token')){
    return fetch(`${process.env.REACT_APP_BASE_URL}/admin/check/jwt`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json()).then(r => r.valid);
  }
  else{
    return false;
  }
}

const CreatePage = () => {
  const [errSt, setErrStatus] = useState('');
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [status, setStatus] = useState('');
  const [showMint, setShowMint] = useState(false);
  const [showList, setShowList] = useState(false);
  const [item, setItem] = useState({});
  const [propertyCount, setPropertyCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [mintCols, setMintCols] = useState([]);
  const [selectedCol, setSelectedCol] = useState({});
  const [categories, setCategories] = useState([]);

  const authedUser = useSelector(state => state.authedUser);
  const appStatus = useSelector(state => state.status);
  const navigate = useNavigate();

  useEffect(() => {
    if (authedUser.authedUser.address){
      creatingNftAllowed(authedUser.authedUser.address.toLowerCase())
      .then(nftAllowed => {
        if (nftAllowed){
          fetch(`${process.env.REACT_APP_BASE_URL}/items/collections/get/create/${authedUser.authedUser.address}`)
          .then(r => r.json())
          .then(res => {
            if (res.success){
              setMintCols(res.collections);
            }
          });
        }
        else{
          adminTokenValid().then(r => {
            console.log(r);
            if (r){
              fetch(`${process.env.REACT_APP_BASE_URL}/items/collections/get/create/${authedUser.authedUser.address}`)
              .then(r => r.json())
              .then(res => {
                if (res.success){
                  setMintCols(res.collections);
                }
              });
            }
            else{
              navigate("/marketplace");
            }
          })
        }
      });
    }
    else{
      navigate('/marketplace');
    }
  }, [authedUser.authedUser.address]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`).then(r => r.json())
    .then(r => {
      if (r.success){
        setCategories(r.categories);
      }
    })
  }, [])

  const fileChangeHandler = (e) => {
    if (e.target.files.length > 0){
        let sizeInMB = (e.target.files[0].size/1024)/1024;
        if (sizeInMB <= 40){
            setImage(e.target.files[0]);
            setErrStatus('');
        }
        else{
            setErrStatus('Videos/Image/Audio above 40MB cannot be uploaded');
        }
    }
  }

  const updateValues = (i) => {
    // console.log(i);
    if (propertyCount !== 1){
      if (i !== propertyCount){
        let delkey, delval;
        Array.from(Array(propertyCount)).map((a, ind) => {
          if (ind+1 >= i && ind+1 < propertyCount){
            delkey = document.getElementById(`key-${ind+2}`).value
            delval = document.getElementById(`value-${ind+2}`).value
            document.getElementById(`key-${ind+1}`).value = delkey;
            document.getElementById(`value-${ind+1}`).value = delval;
          }
        });
      }
    }
    setPropertyCount(propertyCount-1);
  }

  const showProperties = () => {
    return (
      <div id="properties-div">
        {Array.from(Array(propertyCount)).map((a, i) => (
          <div id={`property-num-${i+1}`} className="row mt-3 mb-1">
            <div className="col-12 col-sm-5"><input className="property-key" id={`key-${i+1}`} placeholder={`Type ${i+1}`} /></div>
            <div className="col-12 col-sm-5 mt-3 mt-sm-0"><input className="property-value" id={`value-${i+1}`} placeholder={`Name ${i+1}`} /></div>
            <div className="col-12 col-sm-2 mt-3 mt-sm-0">
                <button style={{background: 'none', border: 'none', cursor: 'pointer'}} onClick={() => {updateValues(i+1);}}>
                    <img src="https://img.icons8.com/office/16/000000/close-window.png"/>
                </button>
            </div>
            <br/>
          </div>
        ))}
        <button className="create-btn mt-3" style={{paddingLeft:'20px'}}  onClick={() => setPropertyCount(propertyCount+1)}>
          Add New Property
        </button>
      </div>            
    )
  }

  const mintNft = async () => {
    if (appStatus){
      setStatus('Minting');
      mintNFT(selectedCol.royalty_percent, authedUser.authedUser.address, item._id, (res) => {
        toast('Successfully created!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        console.log("res", res);
        setItem(res?.item);
        setLoading(false);
        setLoadingMsg('');
        setStatus('Asking to List');
        setShowMint(true);
        // alert('Successfully created!');
        // navigate(`/profile/${authedUser.authedUser.address}`);
      });
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const createItem = async () => {
    if (appStatus){
      if (authedUser.authedUser.address){
        let allowed = await creatingNftAllowed(authedUser.authedUser.address.toLowerCase());
        if (allowed){

          let selectedCateg = document.getElementById('custom-select').value;
          let selectedCollection = document.getElementById('select-collection').value;
          let selectedAll = mintCols.find(c => c._id === selectedCollection);
          console.log(selectedCateg, selectedCollection);
          setSelectedCol(selectedAll);
          let properts = [];
          let notfilled = false;
          Array.from(Array(propertyCount)).forEach((ii, index) => {
              console.log(`key-${index+1}`, `value-${index+1}`)
              let ky = document.getElementById(`key-${index+1}`).value;
              let vl = document.getElementById(`value-${index+1}`).value;
              if (ky && vl){
                  properts.push({key: ky, value: vl});
              }
              else{
                  notfilled = true;
              }
          });
          if (!selectedAll){
            toast('All fields are required. Collection not selected', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('All fields are required');
          }
          else if (notfilled){
            toast('All fields are required: Property, values are empty', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('All fields are required');
          }
          else if (!name || !desc || !image){
            toast('All fields are required. Name, Desc or Image is empty', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('All fields are required');
          }
          else{
            setLoadingMsg('Creating Item');
            setLoading(true);
            const metadata = {
              name: name,
              description: desc,
              creator: authedUser.authedUser.address,
              attributes: properts
            }
            console.log(metadata);
            setStatus('Creating..');
            console.log(name, desc, image, authedUser.authedUser.address, image.type, properts, selectedCollection, selectedCateg);
            let r = await createItemRequest(name, desc, image, metadata, authedUser.authedUser.address, image.type, properts, selectedCollection, selectedCateg);
            if (r.success){
              setItem(r.item);
              setLoading(false);
              setLoadingMsg('');
              setStatus('Asking to Mint');
              setShowMint(true);
            }
            else{
              
              toast('Failed', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert('Failed');
              setLoading(false);
              setLoadingMsg('');
            }
          }
        }
        else{
          toast('This feature is blocked. Contact admin', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
        }
      }
      else{
        toast('Connect Wallet', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        // alert('Connect Wallet');
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  // if (authedUser.authedUser?.address){
    return(
      <div>
        <div className='container mt-4'>
          <div className='row'>
            <h1 className='col-12 create-header font-700'>
              Create an NFT
            </h1>
          </div>
        </div>
          
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <hr style={{
                backgroundImage: 'linear-gradient(90deg, rgb(102 96 209) 0%, rgb(153 38 223) 50%, rgb(211 0 239) 100%)',
                height: '2px'
                }} />
            </div>
          </div>
        </div>
        
        <div className='container create-middle'>
            {/* <div className='row img-div'> */}
            
            
              {/* <div className="col-12"></div>
              {image ? (
                <div className='col-12 col-sm-4 mt-3'>
                  <div className='card onsale-item'>
                    <img variant="top" className="card-img item-img" src={URL.createObjectURL(image)} />
                  </div>
                </div>
  
              ) : (null)} */}
            {/* </div> */}
            <div className="row">
              <div className="col-md-8">
                <div className='row mt-4 name-and-category'>
                <div className="col-12 col-sm-6" style={{color: 'white'}}>
                  <h5 style={{fontWeight: 600}}>Name</h5>
                  <input type="text" placeholder="Name of your NFT" id="title" onChange={(e) => setName(e.target.value)} />
                </div>
                </div>
                <div className='row mt-4 collection-select'>
                    <div className="col-12 mt-3 mt-sm-0 col-sm-6" style={{color: 'white'}}>
                      <h5 style={{fontWeight: 600}}>Category</h5>
                      <select className="px-1 w-100" id="custom-select" >
                        <option value="">-</option>
                          {categories.map((c, ind) => {
                              return(
                                  <option value={c._id}>{c.name}</option>
                              );
                          })}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6" style={{color: 'white'}}>
                        <h5 style={{fontWeight: 600}}>Select Collection to mint from</h5> 
                        <select id="select-collection" className="px-1 w-100" >
                            {mintCols
                            .map(a => {
                              return(
                                <option value={a._id}>{a.name}</option>
                              );
                            })}
                        </select>
                    </div>
                </div>
                <div className='row mt-4'>
                  <div className="col-12 col-sm-8" style={{color: 'white'}}>
                      <h5 style={{fontWeight: 500}}>Description</h5>
                      <textarea type="text" rows="10" placeholder="0 of 1000 characters used." id="descript" onChange={(e) => setDesc(e.target.value)}></textarea>
                  </div>
                </div>
                <div className="row mt-4 mb-3">
                    <div className="col-12 col-sm-8" style={{color: 'white'}}>
                      <h5 style={{fontWeight: 500}}>Properties</h5>
                      {showProperties()}
                    </div>
                </div>
      
                <div className='row properties'></div>
                <div className='row create mt-4'>
                  <div className="col-12 col-sm-6">
                    <button onClick={() => createItem()} className="create-btn" >Create</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className=''>
                  <h4 style={{fontWeight: 600}}>Featured Item</h4>
                  <p>Featured Item: File types supported : JPG, PNG, GIF, SVG </p>
                </div>
                <div className='mt-3'>
                  <input type="file" style={{display: 'none'}} accept="image/*" id="input-file" onChange={fileChangeHandler} />
                  {image ?  (<>
                    <div className='card onsale-item'>
                      <img variant="top" onClick={() => document.getElementById("input-file").click()} className="card-img item-img" src={URL.createObjectURL(image)} />
                    </div>
                  </>) : (
                  <div id="img-upload" onClick={() => document.getElementById("input-file").click()}>
                    <img className='gallery' src={ImgPlaceholder} />
                  </div>
                  )}
                </div>
              </div>
            </div>
          
        </div>
  
        
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <hr style={{
                backgroundImage: 'linear-gradient(90deg, rgb(102 96 209) 0%, rgb(153 38 223) 50%, rgb(211 0 239) 100%)',
                height: '2px'
                }} />
            </div>
          </div>
        </div>
        <CreateModal createModal={showMint} closeModal={() => {setShowMint(false); navigate(`/profile/${authedUser.authedUser.address}`)}} status={status} mintToken={mintNft} listToken={() => {setShowList(true); setShowMint(false)}} />
        <Loader loader={loading} msg={loadingMsg} />
        <ListingModal item={item} setShow={setShowList} show={showList}  />
      </div>
    );  
  // }
  // else{
  //   return(
  //     <Navigate to="/marketplace" />
  //   );
  // }
  
}

export { CreatePage };