
import winIco from "../images/bathicon.png"

export function Loader({loader,msg}) {
    return(
        <>
            {loader &&
                <div className="globalLoader">
                    <div className="loader px-3">
                        <img src={winIco} style={{height: '40px', width: '40px'}} alt="loader" />
                        <div className="msg">{msg}</div>
                    </div>
                </div>
                }
        </>
    )
}