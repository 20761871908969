import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import profileImg from "../../images/profileImg.png"
import defaultBanner from "../../images/defaultBanner.jpg"
import artWork from "../../images/artWork.png"
import artist from "../../images/artist.png"
import editIco from "../../images/editIco.svg"
import defaultcover from "../../images/cover_image_1.png"
import defaultpfp from "../../images/profile_image_1.png"

import "./profilepage.css"
import { Link, useParams } from 'react-router-dom';
import web3 from '../../web3';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../loader';
import BlockedUserModal from "../BlockedUserModal/blockedUserModal";
import ListingModal from '../listingModal';
import { updateUser } from '../../redux/ActionCreators';
import { likeNft, mintNFT, withdrawBidMethod, transferNFTMethod } from '../../helpers/helpers';
import { currencies } from '../../helpers/constants';
import { toast } from 'react-toastify';

const getUser = (address) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/users/get-user/${address}`).then(r => r.json());
}

const getCollectionAddresses = () => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/collections/get-address`).then(r => r.json()).then(r => r.collections.filter(aa => aa.collection_address ? true : false).map(rr => rr.collection_address));
}

// const collectionsAddresses = ["0xea1e77fd0992b5442ce1f45465f898a2b06c90e8", "0x185bab30466bc30b34d73829a19e04124dca7bd0"];

function fetchNfts(address, cursor, chainid){
  var myHeaders = new Headers();
  console.log("X521Ju4Rqa56CLlBo6sGBQSxwlsk9o8h1L9sPXQZ24qQm5vshXTp5wtQPnpp324P");
  myHeaders.append("X-API-Key", "X521Ju4Rqa56CLlBo6sGBQSxwlsk9o8h1L9sPXQZ24qQm5vshXTp5wtQPnpp324P");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  return fetch(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=${chainid}${cursor ? `&cursor=${cursor}` : ''}`, requestOptions)
  .then(response => response.json())
  .catch(error => console.log('error', error));
}

const checkMetadata = (itm, collectionsAddresses) => {
  // if (itm.metadata){
  //   console.log("akjsfnkajfnkjdafnjakdfn", itm);
  //   itm.metadata = JSON.parse(itm.metadata);
  //   if (itm.metadata.name && itm.metadata.description && itm.metadata.ipfs_image){
  //     return true;
  //   }
  //   else{
  //     return false;
  //   }
  // }
  // else{
  //   return false;
  // }
  return collectionsAddresses.includes(itm.token_address.toLowerCase());
}
export const ProfilePage = () => {
  let {address} = useParams();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);
  const [favtItems, setFavtItems] = useState([]);
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [listingModal, setListingModal] = useState(false);
  const [itemForListing, setItemForListing] = useState({});
  const [activeTab, setActiveTab] = useState('collected');

  const dispatch = useDispatch();

  const updateItem = (item, type) => {
    if (type === 'bid'){
      setBids(bids.map(m => {
        if (m.item_id._id === item.item_id._id){
          return {...m, item_id: {...m.item_id, likedBy: item.likedBy}};
        }
        else{
          return m;
        }
      }))
    }
    else if (type === 'transfer'){
      setItems(items.filter(m => m._id !== item._id));
    }
    else if (type === 'owned'){
      setItems(items.map(m => {
        if (m._id === item._id){
          return item;
        }
        else{
          return m;
        }
      }))
    }
    else if (type === 'liked'){
      setFavtItems(favtItems.filter(m => m._id !== item._id));
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (authedUser.authedUser.address === user?.address){
      setShow(true)
    }
    else{
      toast('Connect Wallet with correct account', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });  
      // alert('Connect Wallet with correct account');
    }
  };

  const authedUser = useSelector(s => s.authedUser);
  const appStatus = useSelector(s => s.status);

  const fetchItems = (id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/get-byowner/${id}`).then(r => r.json())
    .then(r => {console.log(r.items); setItems(r.items)});
  }
  
  const fetchFavtItems = (id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/get-liked/${id}`).then(r => r.json())
    .then(r => {console.log(r.items); setFavtItems(r.items)});
  }
  const fetchBids = (id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/bids/user/${id}`).then(r => r.json())
    .then(r => {console.log(r.bids); setBids(r.bids)});
  }

  const isOwner = () => authedUser.authedUser?.address?.toLowerCase() === user?.address?.toLowerCase();

  const mintNft = async (item) => {
    if (appStatus){
      if (isOwner()){
        console.log(item);
        setLoader(true)
        console.log(parseInt(item?.collection_id?.royalty_percent), web3.web3.utils.toChecksumAddress(authedUser.authedUser.address), process.env.REACT_APP_MINTER_CONTRACT_ADDRESS);
        await mintNFT(parseInt(item?.collection_id?.royalty_percent), authedUser.authedUser.address, item._id, (r) => {
          setItems(items.map(it => it._id === item._id ? r.item : it));
        });
        setLoader(false);
      }  
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });      
    }
  }

  const profilePicOrCoverChange = async (e, profileOrCover) => {
    console.log(e, profileOrCover);
    if (e.target.files.length > 0){
      let formdata = new FormData();
      console.log(e.target.files[0]);
      formdata.append(profileOrCover, e.target.files[0], e.target.files[0].name);
      formdata.append("displayName", user.displayName);
      formdata.append("email", user.email);
      formdata.append("handle", user.handle);
      formdata.append("bio", user.bio);
      formdata.append("address", authedUser.authedUser.address.toLowerCase());
  
      fetch(`${process.env.REACT_APP_BASE_URL}/users/edit-profile`, {
        method: 'PUT',
        body: formdata,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
        }
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          setUser(res.user);
          dispatch(updateUser(res.user));
          
          toast('Updated', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
          // alert('Updated');
        }
      });
    }
  }

  const withdrawBid = async (bid) => {
    if (appStatus){
      if (isOwner()){
        setLoader(true);
        console.log(bid);
        withdrawBidMethod(authedUser.authedUser.address, bid, (r) => {
          
          toast('Success', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
          // alert('Success');
          setLoader(false);
          fetchBids(authedUser.authedUser._id);
        }, (r) => {
          toast('Failed ' + (r?.msg ? r?.msg : ''), {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
          // alert('Failed ' + (r?.msg ? r?.msg : ''));
          setLoader(false);
        })
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });      
    }
  }

  useEffect(() => {
    getUser(address).then(r => {
      console.log(r);
      if (r.success){
        setUser(r.user);        
        fetchItems(r.user._id);
        fetchBids(r.user._id);
        fetchFavtItems(r.user._id);
      }
      setLoading(false);
    });
  }, [address]);

  const fetchOtherNFTs = async () => {
    // setFetchLoading(true);
    let col_addresses = await getCollectionAddresses();
    // let col_addresses = ['0xD1A21D267c5AE768Ef9f75F38b16e03490C49e4e'.toLowerCase()];
    console.log(col_addresses);
    let bscNfts = [];
    let cursor = '';
    let r;
    do {
      r = await fetchNfts(user.address, cursor, '0x61');
      console.log(r);
      cursor = r.cursor;
      bscNfts = [...bscNfts, ...r.result];
      if (r.cursor === null){
        break;
      }
    }
    while (r.result.length > 0);
    console.log(bscNfts);
    let bscNftsValid = bscNfts.filter(n => checkMetadata(n, col_addresses));
    console.log(bscNftsValid);
    bscNftsValid.forEach(nft => {
      if (nft.metadata){
        console.log(JSON.parse(nft.metadata));
      }
    })
    let bscDontExists = bscNftsValid.map(n => fetch(`${process.env.REACT_APP_BASE_URL}/items/dontexist/${n.token_id}/${n.token_address.toLowerCase()}/${user.address}`).then(res => res.json()));
    // console.log(bscDontExists);
    let resBsc = await Promise.all(bscDontExists);
    console.log(resBsc);
    let finalBscToAdd = [];
    resBsc.forEach((res, ind) => {
      if (res.success){
        finalBscToAdd.push(bscNftsValid[ind]);
      }
    });
    console.log(finalBscToAdd);
    let final = finalBscToAdd.map(f => fetch(`${process.env.REACT_APP_BASE_URL}/items/create-secondary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        collection_address: f.token_address.toLowerCase(),
        user_address: user.address,
        nft_id: f.token_id,
        name: JSON.parse(f.metadata).name,
        description: JSON.parse(f.metadata).description,
        image: !JSON.parse(f.metadata).image.includes('https') ? JSON.parse(f.metadata).image.replace('http', 'https') : JSON.parse(f.metadata).image,
        attributes: JSON.parse(f.metadata).attributes,
        token_uri: f.token_uri
      })
    }).then(r => r.json()))
    let aa = await Promise.all(final);
    console.log(aa);
    fetchItems(user._id)
    // setFetchLoading(false);
  }
  if (loading){
    return (
      <div className="create-loader">

      </div>
    );
  }
  else if (Object.keys(user).length === 0){
    return (
      <h4>No user with {address} address exists!</h4>
    );
  }
  else if (user.blocked){
    return (
      <BlockedUserModal />
    );
  }
  else{
    return(
      <div className='profilePage'>
      <div className="banner">
        <input style={{display: 'none'}} id="profile-cover-upd" type='file' accept='image/*' onChange={(e) => profilePicOrCoverChange(e, "cover")} />
          <img style={{width: '100%', objectFit: 'scale-down'}} onClick={() => document.getElementById("profile-cover-upd").click()} src={user?.cover?.includes("image_1.png") ? defaultcover : `${process.env.REACT_APP_BASE_URL}/${user.cover}`} alt="Banner" />
      </div>
      <div className="container">
          <div className="profileImgDiv">
              <input style={{display: 'none'}} id="profile-pfp-upd" type='file' accept='image/*' onChange={(e) => profilePicOrCoverChange(e, "profile")} />
              <img onClick={() => document.getElementById("profile-pfp-upd").click()} src={user.profilepic.includes("image_1.png") ? defaultpfp : `${process.env.REACT_APP_BASE_URL}/${user.profilepic}`} style={{borderRadius: '50%'}} alt="profileImg" />
          </div>
          <div className="row mt-4">
              <div className="col-md-4">
                  <div className="sidebarContent">
                      <h3 className="text-white">{user?.displayName ? user?.displayName : 'Unnamed'}</h3>
                      <div className="id">@{user?.handle ? user.handle : 'unnamed'}</div>
                      <div className="address">{user?.address?.substring(0,5)}...{user?.address?.substring(user?.address?.length - 5, user?.address?.length - 1)}</div>
                      <div className="name">Bio</div>
                      <div className="desc">
                      {user?.bio ? user.bio : 'No bio added'} 
                      </div>
                      <button className="editBtn mb-3" onClick={handleShow}>Edit Profile</button>
                      <button className="editBtn mb-3" onClick={fetchOtherNFTs}>Sync Secondary NFTs</button>
                      {/* <button className="editBtn">Trading History</button> */}
                  </div>
              </div>
              <div className="col-md-8">
                  <div className="userNavigation">
                      <div className="links">
                          <div onClick={() => setActiveTab('collected')} className={`link ${activeTab === 'collected' ? 'active' : ''}`}>Collected</div>
                          <div onClick={() => setActiveTab('favorite')} className={`link ${activeTab === 'favorite' ? 'active' : ''}`}>Favorited</div>
                          <div onClick={() => setActiveTab('accepted')} className={`link ${activeTab === 'accepted' ? 'active' : ''}`}>Accepted</div>
                          <div onClick={() => setActiveTab('approval')} className={`link ${activeTab === 'approval' ? 'active' : ''}`}>Awaiting Approval</div>
                          <div onClick={() => setActiveTab('rejected')} className={`link ${activeTab === 'rejected' ? 'active' : ''}`}>Rejected</div>
                          <div onClick={() => setActiveTab('bids')} className={`link ${activeTab === 'bids' ? 'active' : ''}`}>Bids</div>
                      </div>
                      {/* <div className="offer">Offer</div> */}
                  </div>

                  <div className="nftCardMain mt-4">
                  <div className="row">
                      {activeTab === 'collected' ? (
                        items.length > 0 ? items.filter(i => i.approvalRequested === "0").map(it => {
                          return (
                            <div key={it._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <NFTCARD updateItem={(it) => updateItem(it, 'owned')} authedUser={authedUser} setItemForListing={setItemForListing} setListingModal={setListingModal} mintNft={mintNft} isOwner={isOwner} item={it} />
                            </div>
                          );
                        }) : (
                          <h1 className="noDAtaHead">No Data Found</h1>
                        )
                      ) : (<></>)}
                      {activeTab === 'bids' ? (
                        <>
                        {bids.map(bid => {
                          return (
                            <div key={bid._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <BIDCARD updateItem={(it) => updateItem(it, 'bids')} authedUser={authedUser} withdrawBid={withdrawBid} isOwner={isOwner} bid={bid} />
                            </div>
                          );
                        })}
                        </>
                      ) : (<></>)}
                      
                      {activeTab === 'favorite' ? (
                        favtItems.length > 0 ? favtItems.map(it => {
                          return (
                            <div key={it._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <NFTCARD updateItem={(it) => updateItem(it, 'liked')} authedUser={authedUser} setItemForListing={setItemForListing} setListingModal={setListingModal} mintNft={mintNft} isOwner={isOwner} item={it} />
                            </div>
                          );
                        }) : (
                          <h1 className="noDAtaHead">No Data Found</h1>
                        )
                      ) : (<></>)}
                      {activeTab === 'accepted' ? (
                        items.length > 0 ? items.filter(i => parseInt(i.status) === 1 || parseInt(i.status) === 2).map(it => {
                          return (
                            <div key={it._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <NFTCARD updateItem={(it) => updateItem(it, 'owned')} authedUser={authedUser} setItemForListing={setItemForListing} setListingModal={setListingModal} mintNft={mintNft} isOwner={isOwner} item={it} />
                            </div>
                          );
                        }) : (
                          <h1 className="noDAtaHead">No Data Found</h1>
                        )
                      ) : (<></>)}
                      
                      {activeTab === 'approval' ? (
                        items.length > 0 ? items.filter(i => i.approvalRequested === "1").map(it => {
                          return (
                            <div key={it._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <NFTCARD updateItem={(it) => updateItem(it, 'owned')} authedUser={authedUser} setItemForListing={setItemForListing} setListingModal={setListingModal} mintNft={mintNft} isOwner={isOwner} item={it} />
                            </div>
                          );
                        }) : (
                          <h1 className="noDAtaHead">No Data Found</h1>
                        )
                      ) : (<></>)}
                      {activeTab === 'rejected' ? (
                        items.length > 0 ? items.filter(i => i.approvalRequested === "2").map(it => {
                          return (
                            <div key={it._id} className="col-sm-6 col-lg-6 col-xxl-4 col-xl-4 mb-4">
                              <NFTCARD updateItem={(it) => updateItem(it, 'owned')} authedUser={authedUser} setItemForListing={setItemForListing} setListingModal={setListingModal} mintNft={mintNft} isOwner={isOwner} item={it} />
                            </div>
                          );
                        }) : (
                          <h1 className="noDAtaHead">No Data Found</h1>
                        )
                      ) : (<></>)}

                    {/* {NFTS.length == 0 &&
                    } */}
                  </div>
                  {/* <div className="row text-align-center">
                    <div className='itemBtnDiv my-4 text-center'>
                        <button className='btn showmore mkof'  > Show More</button>
                    </div>
                  </div> */}
                </div>
              </div>
          </div>
      </div>
      <EditProfileModal show={show} setUser={setUser} handleClose={handleClose} authedUser={authedUser} />
      <Loader loader={loader} msg={'Please Wait'}  />
      <ListingModal show={listingModal} item={itemForListing} setShow={setListingModal} />
    </div>

    );
  }
}

const EditProfileModal = ({authedUser, show, handleClose, setUser}) => {
  const [profile, setProfile] = useState('');
  const [cover, setCover] = useState('');

  const profileChange = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0){
      setProfile(e.target.files[0]);
    }
  }
  const coverChange = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0){
      setCover(e.target.files[0]);
    }
  }

  const dispatch = useDispatch();

  const saveChanges = async () => {
    const handle = document.getElementById("profile-edit-handle").value;
    let handleValid = await fetch(`${process.env.REACT_APP_BASE_URL}/users/check-handle?handle=${handle}&uid=${authedUser.authedUser._id}`).then(r => r.json());
    if (handleValid.exists){
      toast('Handle already exists', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
    else{
      let formdata = new FormData();
      if (profile){
        console.log(profile);
        formdata.append("profile", profile, profile.name);
      }
      if (cover){
        console.log(cover);
        formdata.append("cover", cover, cover.name);
      }
      const displayName = document.getElementById("profile-edit-displayName").value;
      const email = document.getElementById("profile-edit-email").value;
      const bio = document.getElementById("profile-edit-bio").value;
      formdata.append("displayName", displayName);
      formdata.append("email", email);
      formdata.append("handle", handle);
      formdata.append("bio", bio);
      formdata.append("address", authedUser.authedUser.address.toLowerCase());
  
      fetch(`${process.env.REACT_APP_BASE_URL}/users/edit-profile`, {
        method: 'PUT',
        body: formdata,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
        }
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          setUser(res.user);
          dispatch(updateUser(res.user));
          toast('Updated', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
          // alert('Updated');
          handleClose();
        }
      });  
    }
  }

  return(
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
          <div className="editProfileModal">
              <h2>Edit Profile</h2>
              <div className="banner">
              <img src={(cover || authedUser.authedUser.cover) ? (cover ? URL.createObjectURL(cover) : (authedUser.authedUser?.cover?.includes("image_1.png") ? defaultcover : `${process.env.REACT_APP_BASE_URL}/${authedUser.authedUser.cover}`)) : ''} alt="" />
              <div className="editIco">
                  <label >
                    <input type="file" accept="image/*" onChange={coverChange}/>
                    <img className='mb-2' src={editIco} alt="editIcon" />
                    <p className='mb-0'>edit</p>
                  </label>
              </div>
              </div>
              <div className="eForm">
                  <div className="profileImgDiv">
                      <div className="imgDiv">
                          <img style={{borderRadius: '50%'}} src={(profile || authedUser.authedUser.profilepic) ? (profile ? URL.createObjectURL(profile) : (authedUser.authedUser.profilepic.includes("image_1.png") ? defaultpfp : `${process.env.REACT_APP_BASE_URL}/${authedUser.authedUser.profilepic}`)) : ""} alt="" />
                          <div className="editIco">
                              <label >
                                  <input type="file" accept="image/*" onChange={profileChange} />
                                  <img className='mb-2' src={editIco} alt="editIcon" />
                                  <p className='mb-0'>edit</p>
                              </label>
                          </div>
                      </div>
                  </div>
                  <div className="imgText">
                      <p>Profile image: Recommend an image at least 256px by 256px. </p>
                      <p>Cover image: Recommend an image at least 1600px by 400px.</p>
                  </div>
                  <div className=" mt-4">
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Display Name</label>
                          <input type="text" defaultValue={authedUser.authedUser.displayName} id="profile-edit-displayName" className="form-control text-white" placeholder="Ox04...31Cf" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="username">Handle</label>
                          <input type="text" defaultValue={authedUser.authedUser.handle} id="profile-edit-handle" className="form-control text-white" placeholder="@" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="username">Email</label>
                          <input type="email" defaultValue={authedUser.authedUser.email} id="profile-edit-email" className="form-control text-white" placeholder="Email" />
                          <label htmlFor="username" className='mt-1'>This will not be shown on your profile</label>
                      </div>
                      <div className="form-group">
                          <label htmlFor="username">Bio (optional)</label>
                          <textarea className='form-control text-white' defaultValue={authedUser.authedUser.bio} name="" id="profile-edit-bio" cols="30" rows="5"></textarea>
                      </div>

                  </div>
                  <div className="btnMain py-4">
                      <Button className='Cancel'  onClick={handleClose}>
                          Cancel
                      </Button>
                      <Button className='save themeBtn'  onClick={saveChanges}>
                          Save Changes
                      </Button>
                  </div>
              </div>
          </div>
      </Modal.Body>
    </Modal>
  );
}

const BIDCARD = ({updateItem, authedUser, withdrawBid, bid, isOwner}) => {
  return (
      <div className="artworkCard h-100">
        <div className="favorite" onClick={() => {
          likeNft(bid.item_id._id, authedUser).then(res => {
            if (res?.success){
              updateItem(res?.item);
            }
          })
        }}>
          {bid.item_id?.likedBy?.includes(authedUser.authedUser?._id) ? <i class="fas fa-heart me-2 red-color"></i> : <i class="far fa-heart me-2 red-color"></i>}
        </div>
        <Link to={"/detail/"+bid.item_id.nft_id} className="imgDiv">
          <img src={`${process.env.REACT_APP_BASE_URL}/${bid.item_id.image_local}`} alt="artWork" />
        </Link>
        <div className="content">
          <div className="nameMain">
            <h6>{bid.item_id.name}</h6>
            <p>{bid?.item_id?.collection_id?.name}</p>
          </div>
          <div className="profileDiv">
            {/* {bid.item_id?.status !== "0" ? (
            <div className="imgDiv">
              <img src={artist} alt="artist" />
            </div>
            ) : null} */}
            <div className="amount">
              <div className="pri">Price: {bid.price} <img src={currencies[bid.currency].icon} /></div>
              <div onClick={() => {
                withdrawBid(bid);
              }} className="am">{isOwner() ? 'Withdraw' : ''}</div>
            </div>
          </div>
        </div>
      </div>
  )
}


const NFTCARD = ({updateItem, authedUser, setItemForListing, item, isOwner, mintNft, setListingModal}) => {
  const [transferModal, setTransferModal] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [transferAddress, setTransferAddress] = useState('');
  const transferModalShow = () => setTransferModal(true);
  const transferModalHide = () => setTransferModal(false);

  const transferNFT = () => {
    if (transferAddress){
      if (web3.web3.utils.isAddress(transferAddress)){
        if (parseInt(item?.status) === 0){
          transferNFTMethod(transferAddress, authedUser.authedUser.address, item?.collection_id?.collection_address ? item?.collection_id?.collection_address : process.env.REACT_APP_MINTER_CONTRACT_ADDRESS, item?.nft_id, item?._id, () => {
            resett();
          });
        }
        else{
          toast('Delist your NFT to transfer', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
        }
      }
      else{
        toast('Transfer address is invalid', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });    
      }
    }
    else{
      toast('Enter the transfer address', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const resett = () => {
    setTransferLoading(false);
    setTransferAddress('');
    transferModalHide();
  }
  
  return (
      <div className="td-none">
        <div className="artworkCard">
          {(authedUser.authedUser._id === item?.owner && item?.minted) ? (
          <div className="transfer" onClick={transferModalShow}>
            <i class="fas fa-share me-2 red-color"></i>
          </div>
          ) : (<></>)}
          <div className="favorite" onClick={() => {
            likeNft(item._id, authedUser).then(r => {
              if (r?.success){
                updateItem(r?.item);
              }
            })
          }}>
          {item?.likedBy.includes(authedUser.authedUser?._id) ? <i class="fas fa-heart me-2 red-color"></i> : <i class="far fa-heart me-2 red-color"></i>}
          {/* <i class="fal fa-heart me-2"></i> */}
          </div>
          <Link to={item.minted ? "/detail/"+item.nft_id : "#"} className="imgDiv">
            <img src={`${process.env.REACT_APP_BASE_URL}/${item.image_local}`} alt="artWork" />
          </Link>
          <div className="content">
            <div className="nameMain">
              <h6>{item.name}</h6>
              <p>{item?.collection_id?.name}</p>
            </div>
            <div className="profileDiv">
              {item?.status !== "0" ? (
              <div className="imgDiv">
                <img src={artist} alt="artist" />
              </div>
              ) : null}
              {item?.approvalRequested === "0" ? 
              <div className="amount">
                <div className="pri">{item?.minted ? (item?.status === "0" ? "Status" : "Price") : isOwner() ? "Status" : ""}</div>
                <div className="btnDiv">

                <button onClick={() => {
                  if (!item?.minted){
                    mintNft(item);
                  }else if (item?.status === "0"){
                    setItemForListing(item);
                    setListingModal(true);
                  }
                }} className="themeBtn">{item?.minted ? (item?.status === "0" ? isOwner() ? "List Now" : "Unlisted" : item?.price) : isOwner() ? 'Mint Now' : ""}</button>
                </div>
              </div> : <></>}
            </div>
          </div>
        </div>
        <Modal show={transferModal} className="listing-modal" centered={true}>
          <Modal.Header style={{textAlign: 'center', fontSize: '32px'}} className="py-2">
            <span>Transfer your NFT</span>
          </Modal.Header>
          <ModalBody>
            <div className='p-3'>
              <label style={{textAlign: 'left', fontSize: '18px'}}>Enter Address:</label>
              <InputGroup className="mb-3">
                <Form.Control
                  value={transferAddress} onChange={(e) => setTransferAddress(e.target.value)}
                  placeholder="Recipient's address"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </InputGroup>
            </div>
          </ModalBody>

          <Modal.Footer>
          <div className="WalletModal w-100">
            <div className="btnDiv mintModalBtn mb-1"> 
              <Button color="secondary" className='w-100 me-3'  style={{fontSize: '20px'}} disabled={transferLoading} onClick={() => transferNFT()}>Transfer NFT</Button>
              <Button color="secondary" className='w-100' style={{fontSize: '20px'}} disabled={transferLoading} onClick={() => resett()}>Cancel</Button>
            </div>
          </div>
          </Modal.Footer>
      </Modal>

      </div>
    )
}
  




