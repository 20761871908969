import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import { deleteCategoryReq, replaceCategory, updateCategory } from "../../redux/ActionCreators";
import ImgPlaceholder from '../../images/galleryIcon.png'
import { useAlert } from "react-alert"

export default function EditCategoryModal({show, setShow, category, updateCategory, categories}){
  const [editedImage, setEditedImage] = useState('');
  const [editedName, setEditedName] = useState(category.name);

  useEffect(() => {
    setEditedName(category?.name);
  }, [category]);

  const editCategory = async () => {
    const selectedCateg = category.name;
    if (editedName !== '' | editedImage !== ''){
      var myHeadersBoth = new Headers();
      myHeadersBoth.append('Authorization', `Bearer ${localStorage.getItem('token')}`)

      var formdataBoth = new FormData();
      if (editedImage){
        console.log("added");
        formdataBoth.append("categImg", editedImage, editedImage.name);
      }
      formdataBoth.append("new_name", editedName ? editedName : category.name);
      formdataBoth.append("prev_name", category.name);
      // formdataBoth.append("prev_img", catEdit.image);
    
      var requestOptionsBoth = {
        method: 'PUT',
        headers: myHeadersBoth,
        body: formdataBoth,
        redirect: 'follow'
      };
      let rBoth = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/edit`, requestOptionsBoth).then(res => res.json());
      updateCategory(selectedCateg, rBoth.category);
      alert('Successful')
      resett();
    }
    else{
        alert('Please enter at least one field to edit!');
    }
  }

  const editFileChangeHandler = (e) => {
    if (e.target.files.length > 0){
        // console.log(e.target.files[0]);
        setEditedImage(e.target.files[0]);
    }
  }
//   console.log(show, setShow)

  const resett = () => {
    setShow(false);
    setEditedImage('');
    setEditedName('');
  }

  return(
    <Modal style={{color: 'white'}} isOpen={show}>
      <ModalHeader toggle={() => setShow(!show)}>Edit Category : {category?.name}</ModalHeader>
      <ModalBody>
        <div className="row px-3 mt-4 mb-3"> 
            <div className="col-12">
              <label style={{color: 'white'}} htmlFor="title">New Name :</label> <br/>
              <input type="text" style={{color: 'black'}} className="w-100 px-2 py-1" placeholder="Name of Category" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
            </div>
        </div>
        <div className="row px-3  mt-4 mb-3" >
          <div className="col-12">
            <label style={{color: 'white'}} htmlFor="title">New Image :</label> <br/>
            <div id="edit-categ-upload" style={{height: '200px'}} onClick={() => document.getElementById("input-edit-categ-file").click()}>
              {editedImage ? (
                <img variant="top" className="card-img item-img" style={{
                  width: 'auto',
                  height: '100%'
                }} src={URL.createObjectURL(editedImage)} />
              ) : (
                <img src={category.img} style={{
                  width: 'auto',
                  height: '100%'
                }} />
              )}
                
            </div>
            <input type="file" style={{display: 'none'}} id="input-edit-categ-file" onChange={editFileChangeHandler} accept="image/*" />
          </div>
        </div>
        <div className="row mt-4 mb-3" style={{color: 'white'}}>
            <div className="col-12 col-sm-6 offset-sm-3 mt-3">
                <div className="card onsale-item" >
                </div>
            </div>
        </div>
        {/* <div className="row mt-4 mb-3">
            <div className="col-12 col-sm-6">
                <button className="add-category-btn" onClick={() => editCategory()}>Edit Category</button>
            </div>
        </div> */}

      </ModalBody>
      <ModalFooter>
        <Button onClick={() => {resett()}}>Cancel</Button>
        <Button onClick={() => editCategory()}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}