import React from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery.min.js";
// slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Provider } from "react-redux";
import Main from "./main";
import { ConfigureStore } from "./redux/configureStore";

const store = ConfigureStore();

// import Slider1 from './component/slickSlider';
function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export { App };
