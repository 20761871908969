import React, { useEffect } from 'react'
import copy from 'copy-to-clipboard';

import "./detail.css"
import pimg from '../../images/productimage.png';
import bicon from '../../images/bathicon.png';
import shareIco from '../../images/shareIco.svg';
import optionIco from '../../images/optionIco.svg';
import artWork from "../../images/artWork.png"
import artWork2 from "../../images/artWork2.png"
import artWork3 from "../../images/artWork3.png"
import artWork4 from "../../images/artWork4.png"
import bluePinkEffect from "../../images/bluePinkEffect.png"
import blueEffect from "../../images/blueEffect.png"
import artist from "../../images/artist.png"
import propertyIco from "../../images/propertyIco.svg"
import closeIco from "../../images/closeIco.svg"
import checkMark from "../../images/checkMark.svg"
import Button from 'react-bootstrap/Button';
import MarketPlaceABI from '../../ethereum/WinwinMarketplaceV1.json';
import web3 from '../../web3';
import Modal from 'react-bootstrap/Modal';
import ERC20ABI from '../../ethereum/ERC20.json';
import telegramIcon from "./../../images/telegram.png";
import whatsappIcon from "./../../images/whatsapp.png";
import facebookIcon from "./../../images/facebook copy.png";
import linkedinIcon from "./../../images/linkedin.png";
import twitterIcon from "./../../images/twitter.png";
import { ReactComponent as ShareIco1} from "./../../images/shareIco1.svg";
import BlockedItemModal from '../BlockedItemModal/blockedUserModal';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Filler} from 'chart.js'
import { Line } from 'react-chartjs-2';

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { currencies } from '../../helpers/constants';
import BigNumber from 'big-number/big-number';
import { Loader } from '../loader';
import { biddingAllowed, buyingAllowed, cancelListingAllowed, endingAuctionAllowed, likeNft } from '../../helpers/helpers';
import { toast } from 'react-toastify';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' ,
      labels: {
        color:"#fff"
    }
    
    },
    title: {
      display: false,
      text: 'Price History',
      color:"#fff"
    },
    
   
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: {
      // barPercentage: 1.6,
      grid: {
        // display: false,
        color: 'rgba(144, 150, 188,0)',
        // zeroLineColor: "transparent"
      },
      ticks: {
        // suggestedMin: 0,
        // suggestedMax: 125000,
        // padding: 2,
        // backdropPadding: 2,
        // beginAtZero:true,
        backdropColor: "rgba(255,255,255,1)",
        color: '#fff',
        font: {
          // family: "Montserrat", // Add your font here to change the font of your y axis
          size: 12
        },
        major: {
          enable: true
        }
      }
    },
    xAxes: {
      // barPercentage: 1.6,
      grid: {
        // display: false,
        color: 'rgba(255,255,255,0)',

        zeroLineColor: "#fff"
      },
      ticks: {
        // padding: 20,
        color: '#fff',
        font: {
          // family: "Montserrat", // Add your font here to change the font of your x axis
          size: 12
        },

        major: {
          enable: false
        }
      }
    }
  }
  
};


const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const labels = months.slice(7 + (new Date()).getMonth(), 13).concat(months.slice((new Date()).getMonth() >= 5 ? (new Date()).getMonth() - 5 : 0, (new Date()).getMonth() + 1));
    
// const dataYearDemo = ;

export function ProductDetail() {
  let { token_id } = useParams();
  var [ chartData, setChartData ]=useState(null)
  const [ show, setShow ] = useState(false);
  const [ item, setItem ] = useState({});
  const [ tradeHistory, setTradeHistory ] = useState([]);
  const [ currentBids, setCurrentBids ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState('');
  const [showPurchase, setShowPurchase] = useState(false);
  const [bidModal, setBidModal] = useState(false);
  const [copyLink, setCopyLink] = useState(false);
  const [moreFromCol, setMoreFromCollections] = useState([]);
  const [dataYearDemo, setPriceHistory] = useState({
    labels,
    datasets: [
      {
       fill:true,
        label: 'Dataset 1',
        data: [0,0,0,0,0,0],
        borderColor: '#D279F5',
        backgroundColor: 'rgba(210, 121, 245, 0.3)',
      },
    ],
   });
  const handleCloseBidModal = () => setBidModal(false);
  const authedUser = useSelector(state => state.authedUser);
  const appStatus = useSelector(state => state.status);
  const navigate = useNavigate();
  const location = useLocation()

  const handleClosePurchase = () => {setShowPurchase(false); navigate(`/profile/${authedUser.authedUser.address}`)};

  const isOwner = () => authedUser.authedUser?._id === item?.owner?._id;

  const isERC20Approved = async (tokenAddress, amount, spender, owner) => {
    let erc20 = new web3.web3.eth.Contract(ERC20ABI, tokenAddress);
    let approvedAmount = await erc20.methods.allowance(owner, spender).call();
    let decimals = await erc20.methods.decimals().call();
    console.log(approvedAmount, decimals, approvedAmount < amount*(10**decimals), amount);
    if (approvedAmount < amount*(10**decimals) ){
      await erc20.methods.approve(spender, (amount*(10**decimals)).toString()).send({
        from: owner
      });
      return decimals;
    }
    return decimals;
  }

  const handleShowPurchase = async () => {
    console.log(item);
    if (appStatus){
      if (authedUser.authedUser.address){
        if (authedUser.authedUser._id !== item.owner._id){
          let buyAccess = await buyingAllowed(authedUser.authedUser.address.toLowerCase());
          if (buyAccess){
            let aa = new web3.web3.eth.Contract(MarketPlaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
            let r;
            setLoader("Please wait...");
            setLoader(true);
            try{
              if (item?.currency === 'bnb'){
                r = await aa.methods.BuyItem(parseInt(item.marketplace_id)).send({
                  from: authedUser.authedUser.address,
                  value: web3.web3.utils.toWei(item?.price.toString())
                });
              }
              else{
                console.log(currencies[item?.currency].address, item?.price, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS, authedUser.authedUser.address);
                await isERC20Approved(currencies[item?.currency].address, item?.price, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS, authedUser.authedUser.address);
                console.log(parseInt(item.marketplace_id));
                r = await aa.methods.BuyItem(parseInt(item.marketplace_id)).send({
                  from: authedUser.authedUser.address
                });
              }
              
              fetch(`${process.env.REACT_APP_BASE_URL}/items/buy`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  user_id: authedUser.authedUser._id,
                  item_id: item._id
                })
              }).then(r => r.json()).then(r => {
                if (r.success){
                  setLoader("");
                  setLoader(false);      
                  setShow(false);
                  setShowPurchase(true);
                }
              });  
            }catch(e){
              setLoader("");
              setLoader(false);      
              console.log(e);
              toast('Failed', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert('Failed');
            }
          }
          else{
            toast('This feature is blocked. Contact admin', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });      
          }
        }
        else{
          toast('Youre the owner', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Youre the owner');
        }
      }
      else{
        toast('Connect Wallet', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        // alert('Connect Wallet');
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
      
  }

  const getTradeHistory = (item_id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/trade-history/${item_id}`)
    .then(r => r.json())
    .then(res => {
      console.log(res);
      if (res.success){
        setTradeHistory(res.txs);
      }
    })
  }

  const getPriceHistory = (item_id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/price-history/${item_id}`)
    .then(r => r.json())
    .then(res => {
      console.log("price hsit", res);
      if (res.success){
        let arr = [0,0,0,0,0,0];
        let countArr = [0,0,0,0,0,0];
        let currMonth = new Date().getMonth();
        res.txs.forEach((i) => {
          let d = new Date(i.createdAt);
          let month = d.getMonth();
          let diff = currMonth - month;
          if (diff < 6){
            arr[diff] += parseFloat(i.price);
            countArr[diff]++;
          }
        });
        arr.forEach((a, i) => {
          if (countArr[i] > 0){
            arr[i] = a/countArr[i];
          }
        });
        arr.reverse();
        console.log(arr);
        console.log(labels);
        setPriceHistory({
          labels,
          datasets: [
            {
             fill:true,
              label: 'Dataset 1',
              data: arr,
              borderColor: '#D279F5',
              backgroundColor: 'rgba(210, 121, 245, 0.3)',
            },
          ],
         });
      }
    })
  }

  const updateMoreFromItems = (item) => {
    setMoreFromCollections(moreFromCol.map((i) => {
      if (i._id === item._id){
        return item;
      }
      else{
        return i;
      }
    }))
  }

  const getMoreFromCollection = (id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/collections/items/${id}`)
    .then(r => r.json()).then(res => {
      if (res.success){
        setMoreFromCollections(res.items);
      }
    })
  }

  const fetchInitial = async (tid) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/items/get/${tid}`)
    .then(r => r.json()).then(r => {
      console.log(r);
      if (r.success){
        setItem(r.item);
        getPriceHistory(r.item._id);
        getTradeHistory(r.item._id);
        getMoreFromCollection(r.item._id);
        if (r.item.status === '2'){
          getBids(r.item._id);
        }
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchInitial(token_id);
  }, [token_id]);

  const updateLikedItem = (item) => {
    setItem({...item, likedBy: item.likedBy});
  }

  const validateAmount = (bidamt) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/bids/validate/${item._id}/${bidamt}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
      }
    }).then(r => r.json())
  }

  const bidItem = async () => {
    if (appStatus){
      let bidAccess = await biddingAllowed(authedUser.authedUser.address.toLowerCase());
      if (bidAccess){
        if (!isOwner()){
          let bidamt = parseFloat(document.getElementById('bid-amt').value);
          if (bidamt > 0){
            let valid = await validateAmount(bidamt);
            if (valid.valid){
              try{
                setLoaderMsg('Bidding... Please wait!');
                setLoader(true);
                setBidModal(false);
      
                let contract = new web3.web3.eth.Contract(MarketPlaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
                let contractprice, sendObj = {
                  from: authedUser.authedUser.address
                };
                if (item?.currency === 'bnb'){
                  console.log(valid.amount);
                  sendObj = {
                    ...sendObj,
                    value: web3.web3.utils.toWei(valid.amount.toString())
                  };
                  contractprice = "0";
                }
                else{
                  let decimals = await isERC20Approved(currencies[item?.currency].address, bidamt, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS, authedUser.authedUser.address);
                  contractprice = new BigNumber(valid.amount*(10**decimals));
                }
                let r = await contract.methods.PlaceABid(item?.marketplace_id, contractprice).send(sendObj);
                console.log(r);
                await fetch(`${process.env.REACT_APP_BASE_URL}/bids/add`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
                  },
                  body: JSON.stringify({
                    bidamt,
                    item_id: item?._id
                  })
                }).then(r => r.json()).then(r => {
                  getBids(item?._id);
                  setLoader(false);
                  setLoaderMsg(false);
                });  
              }
              catch(e){
                setLoader(false);
                setLoaderMsg(false);
                toast('Error in bidding', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  theme: 'dark'
                });
              }
            }
            else{
              toast(valid.msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert(valid.msg);
            }
          }
          else{
            toast('Enter value > 0', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            // alert('Enter value > 0');
          }
        }
        else{
          toast('Owner cannot bid', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          // alert('Owner cannot bid');
        }
      }
      else{
        toast('This feature has been blocked. Contact admin', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });  
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const cancelListing = async () => {
    if (appStatus){
      let cancelAccess = await cancelListingAllowed(authedUser.authedUser.address.toLowerCase());
      if (cancelAccess){
        if (isOwner()){
          try{
            setLoaderMsg('Cancelling Listing... Dont close the window');
            setLoader(true);
            let contract = new web3.web3.eth.Contract(MarketPlaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
            let r = await contract.methods.cancelOrder(item?.marketplace_id).send({from : authedUser.authedUser.address});
            console.log(r);
            let res = await fetch(`${process.env.REACT_APP_BASE_URL}/items/listing/cancel`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
              },
              body: JSON.stringify({
                item_id: item?._id,
                // txId: r.transactionHash
              })
            }).then(r => r.json())
            if (res.success){
              setLoader(false);
              setLoaderMsg('');
              fetchInitial(token_id);
              toast('Success', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert('Success');
            }
            else{
              setLoader(false);
              setLoaderMsg('');
              toast(res.msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert(res.msg);
            }
          }catch(e){
            setLoader(false);
            setLoaderMsg('');
            toast('Failed to cancel listing', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }
        }
        else{
          toast("You're not the owner", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
  
        }
      }
      else{
        toast('This feature has been blocked. Contact admin', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const endAuction = async () => {
    if (appStatus){
      let endAuctionAccess = await endingAuctionAllowed(authedUser.authedUser.address.toLowerCase());
      if (endAuctionAccess){
        if (isOwner()){
          try{
            setLoaderMsg('Auction Ending\nDont close the window');
            setLoader(true);
            let contract = new web3.web3.eth.Contract(MarketPlaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
            let r = await contract.methods.EndAuction(item?.marketplace_id).send({from : authedUser.authedUser.address});
            console.log(r);
            let res = await fetch(`${process.env.REACT_APP_BASE_URL}/auctions/end`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
              },
              body: JSON.stringify({
                item_id: item?._id,
                txId: r.transactionHash
              })
            }).then(r => r.json())
            if (res.success){
              setLoader(false);
              setLoaderMsg('');
              fetchInitial(token_id);
              toast('Success', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert('Success');
            }
            else{
              setLoader(false);
              setLoaderMsg('');
              toast(res.msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
              // alert(res.msg);
            }
          }catch(e){
            setLoader(false);
            setLoaderMsg('');
            toast('Failed to end auction', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }
        }
        else{
          toast("You're not the owner", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
        }  
      }
      else{
        toast('This feature has been blocked. Contact admin', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    }
    else{
      toast('App has been down. Contact admin', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });      
    }
  }

  const getBids = async (item_id) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/bids/all/${item_id}`)
    .then(r => r.json())
    .then(res => {
      if (res.success){
        setCurrentBids(res.bids.sort((a, b) => {
          return b.price - a.price
        }));
      }
    })
  }
  const handleCopyLink = () => {
    setCopyLink(true)
    copy(window.location.href);
    toast('Link Copied', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark'
    });
    setTimeout(() => {
      setCopyLink(false)
    }, 4000);
  }

  if (loading){
    return (
      <div className="create-loader">
      </div>
    );
  }
  else if (Object.keys(item).length === 0){
    return(
      <h4>No item with token id {token_id} exists!</h4>
    );
  }
  else if (item?.banned){
    return(
      <BlockedItemModal />
    );
  }
  else if (item?.colBanned){
    return(
      <BlockedItemModal />
    );
  }
  else{
    return (
      <section className="productDetail">
        <img className="effect1" src={bluePinkEffect} alt="bluePinkEffect" />
        <img className="effect2" src={bluePinkEffect} alt="bluePinkEffect" />
        <img className="effect3" src={blueEffect} alt="bluePinkEffect" />
    
        <div className="container p-cont">
          <div className="row p-details">
            <div className="col-lg-8 order-2 order-lg-1 p-desc">
              <div className=" product-1">
                <div className="col-6 p-name">
                  <h2 className='font-700'>{item?.name}</h2>
                  <p style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${item?.collection_id?.trimmedname}`)}>{item?.collection_id?.name}</p>
                </div>
                <div className="col-6 p-ins ">
                  <div className="likes " style={{cursor: 'pointer'}} onClick={() => {
                    likeNft(item?._id, authedUser).then(res => {
                      if (res?.success){
                        setItem({...item, likedBy: res?.item.likedBy});
                      }
                    })
                  }}>
                    {item?.likedBy.includes(authedUser.authedUser?._id?.toLowerCase()) ? <i className="fas fa-heart me-2"></i> : <i className="far fa-heart me-2"></i>}
                    {/* <i class="fal fa-heart me-2"></i> */}
                    {item?.likedBy.length}
                  </div>
                  <div class="dropdown dp-share">
                    
                    
                  <div className="shares btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={shareIco} alt="share" />
                  </div>
                    {/* <button
                      class="btn dropdown-toggle color-light"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={shareIcon} />
                    </button> */}
                    <ul class="dropdown-menu" style={{ background: 'transparent', border: 'none' }}>
                      <div className={"dropItem_share"} style={{display: 'flex'}}>
                        {/* <li>
                          <a class="px-1 dropdown-item" target="_blank">
                            <img
                              src={whatsappIcon}
                              // onClick={() => navigate("/")}
                              className="iconSocial"
                            />
                          </a>
                        </li>
                        <li>
                          <a class="px-1 dropdown-item" target="_blank" href="#">
                            <img
                              src={facebookIcon}
                              // onClick={() => navigate("/")}
                              className="iconSocial"
                            />
                          </a>
                        </li>
                        <li>
                          <a class="px-1 dropdown-item" target="_blank" href="#">
                            <img
                              src={twitterIcon}
                              // onClick={() => navigation("/")}
                              className="iconSocial"
                            />
                          </a>
                        </li>
                        <li>
                          <a class="px-1 dropdown-item" target="_blank" href="#">
                            <img
                              src={linkedinIcon}
                              // onClick={() => navigation("/")}
                              className="iconSocial"
                            />
                          </a>
                        </li>
                        <li>
                          <a class="px-1 dropdown-item" target="_blank" href="#">
                            <img
                              src={telegramIcon}
                              // onClick={() => navigation("/")}
                              className="iconSocial"
                            />
                          </a>
                        </li> */}
                        <li>
                          <div onClick={()=>handleCopyLink()} className={copyLink ?
                            "px-1 shareLinkIco copy":
                            "px-1 shareLinkIco"
                            }>
                            <div>
                            <ShareIco1 />
                            </div> 
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>

                  {/* <div className="option">
                    <img src={optionIco} alt="share" />
                  </div> */}
                </div>

              </div>
              <div className="product-2">
                <p className="p2-price ">
                  {item?.status !== "2" ? "PRICE" : ""}
                  {item?.status === "2" ? currentBids.length > 0 ? "HIGHEST BID" : "MINIMUM PRICE" : ""}
                </p>
                <h1 className="p2-nums" >
                  {item?.status === "0" ? 0 : ''}
                  {item?.status === "1" ? item?.price : ''}
                  {item?.status === "2" ? currentBids.length > 0 ? currentBids[0].price : item?.price : ''} 
                  {currencies[item?.currency].symbol}</h1>
                <p className="p2-percent">~${(280*(item?.price ? parseFloat(item?.price) : 0)).toFixed(2)}</p>
                {isOwner() ? (
                  <>
                  {item?.status === '0' ? (<>
                    {parseInt(item?.approvalRequested) === 1 ? (
                      <button className="p2-btn text-light">Awaiting Approval</button>
                    ) : (
                      <button className="p2-btn text-light">Unlisted</button>
                    )}
                  </>) : (<></>)}
                  {item?.status === '1' ? (<>
                    <button onClick={() => cancelListing()} className="p2-btn text-light">Cancel Listing</button>
                  </>) : (<></>)}
                  {item?.status === '2' ? (<>
                    <button onClick={() => cancelListing()} className="p2-btn text-light">Cancel Auction</button>
                    <button onClick={() => endAuction()} className="p2-btn text-light">End Auction</button>
                  </>) : (<></>)}
                  </>
                ) : ( 
                  <>
                  {item?.status === '0' ? (<>
                    {parseInt(item?.approvalRequested) === 1 ? (
                      <button className="p2-btn text-light">Awaiting Approval</button>
                    ) : (
                      <button className="p2-btn text-light">Unlisted</button>
                    )}
                  </>) : (<></>)}
                  {item?.status === '1' ? (<>
                    <button onClick={() => handleShow()} className="p2-btn text-light">Buy Now</button>
                  </>) : (<></>)}
                  {item?.status === '2' ? (<>
                    <button onClick={() => setBidModal(true)} className="p2-btn text-light">Bid Now</button>
                  </>) : (<></>)}
                  </>
                )}
                {/* <button className="p2-btn text-light" onClick={() => {
                  if (item?.status === "1"){
                    handleShow()
                  }
                  else if (item?.status === "2"){
                    if (isOwner()){
                      alert('Owner cannot bid');
                    }
                    else{
                      setBidModal(true);
                    }
                  }
                }}> 
                  {item?.status === "1" ? "Buy Now" : ""}
                  {item?.status === "2" ? "Bid Now" : ""}
                  {item?.status === "0" ? "Unlisted" : (isOwner() && item?.status === "0") ? "List Now" : ""}
                </button> */}
              </div>
              <div className="col-8 product-3">
                <div className="col-6 ob">
                  <div className="userLogo">
                    <img className="img-fluid" src={bicon} alt="logo" />
                  </div>
                  <div className="user ">
                    <p className="ms-3 mb-0">Owned by <br /> <span style={{cursor: 'pointer'}} onClick={() => navigate(`/profile/${item?.owner?.address}`)}> {item?.owner?.address?.substring(0,5)}...{item?.owner?.address?.substring(item?.owner?.address?.length - 5, item?.owner?.address?.length - 1)}</span></p>
                  </div>
                </div>
                {item?.nft_id ? (
                  <div className="col-6 edition ">
                    <p className="ms-3 mb-0">Edition <br />{item?.nft_id}</p>
                  </div>
                ) : (<></>)}
              </div>
              <div className="descriptipncont">
                <h4 className="mt-4">Description</h4>
                <p>{item?.description}</p>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className=" imgcont">
                <img src={`${process.env.REACT_APP_BASE_URL}/${item?.image_local}`} alt="" className="product-img img-fluid" />
              </div>
              <div className="bg-dark slider"></div>
            </div>
          </div>
            <div className="row offers-details mt-4">
              <div className="col-lg-8 tblcont order-2 order-lg-1">
              {(item?.status === '2' && currentBids.length > 0) ? (
                <>
                <h4 className="mb-4">Offers</h4>
              
                <div className="tblcontent-cont">
                  <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <td scope="col">Price</td>
                        <td scope="col">Bid Date</td>
                        <td scope="col">From</td>
                      </tr>
                    </thead>
                    <tbody>
                      {currentBids.map(bid => {
                        return(
                          <tr key={bid._id}>
                            <td>
                              <div className="profileDiv">
                                {/* <div className="logo">
                                  <img className="img-fluid" src={bicon} alt="logo" />
                                </div> */}
                                <div className="price">
                                  <div className="bath">
                                    <img className="img-fluid" src={currencies[bid.currency].icon} alt="logo" /> {bid.price} {currencies[bid.currency].symbol}
                                  </div>
                                  <div className="dollar" style={{textAlign: 'end'}}>~ ${(280*parseFloat(bid.price)).toFixed(2)}</div>
                                </div>
                              </div>
                            </td>
                            <td>{(new Date(bid?.createdAt)).getDate()}-{(new Date(bid?.createdAt)).getMonth()+1}-{(new Date(bid?.createdAt)).getFullYear()}</td>
                            <td>{bid.bidder.address.substring(0,5)}...{bid.bidder.address.substring(bid.bidder.address - 6, bid.bidder.address.length - 1)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  </div>
                </div>
                {/* <button className="mt-5 mkof themeBtn" >Make Offers</button> */}
                </>
              ) : null}
                <div className="chartMain" >
                  <h4 className="mb-4">Price History</h4>
                  <div className="chartM">
                  <Line options={options} data={chartData ?chartData: dataYearDemo} />
                  </div>
                </div>
                {tradeHistory.length > 0 ? (
                  <div className="tradeHistory">
                    <h4 className="mb-4">Trade History</h4>
                    <div className="tblcontent-cont">
                      <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <td scope="col">Price</td>
                            <td scope="col">From</td>
                            <td scope="col">To</td>
                            <td scope="col">Date</td>
                          </tr>
                        </thead>
                        <tbody>
                          {tradeHistory.map(trade => {
                            return (
                              <tr key={trade._id}>
                                <td>
                                  <div className="profileDiv">
                                    {/* <div className="logo">
                                    </div> */}
                                    <div className="price">
                                      <div className="bath"> 
                                        <img className="img-fluid" src={currencies[trade?.currency].icon} alt="logo" /> {trade?.price} {currencies[trade?.currency].symbol}
                                      </div>
                                      <div style={{textAlign: 'end'}} className="dollar">~ ${(280*parseFloat(trade?.price ? trade?.price : 0)).toFixed(2)}</div>
                                    </div>
                                  </div>
                                </td>
                                <td onClick={() => window.open(`${process.env.REACT_APP_EXPLORER_LINK}/address/${trade?.seller?.address}`)}>{trade?.seller?.address.substring(0, 5)}...{trade?.seller?.address.substring(trade?.seller?.address.length-5, trade?.seller?.address?.length-1)}</td>
                                <td onClick={() => window.open(`${process.env.REACT_APP_EXPLORER_LINK}/address/${trade?.buyer?.address}`)}>{trade?.buyer?.address.substring(0, 5)}...{trade?.buyer?.address.substring(trade?.buyer?.address.length-5, trade?.buyer?.address?.length-1)}</td>
                                <td>{(new Date(trade?.createdAt)).getDate()}-{(new Date(trade?.createdAt)).getMonth()+1}-{(new Date(trade?.createdAt)).getFullYear()}</td>
                              </tr>                              
                            );
                          })}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                ) : (<></>)}
              </div>
              <div className="col-lg-4 detail-cont order-1 order-lg-2">
                <h4 className="det-head">Details</h4>
                <p className="mb-1">Contract Adress {process.env.REACT_APP_MINTER_CONTRACT_ADDRESS.substring(0,5)}...{process.env.REACT_APP_MINTER_CONTRACT_ADDRESS.substring(process.env.REACT_APP_MINTER_CONTRACT_ADDRESS.length - 5,process.env.REACT_APP_MINTER_CONTRACT_ADDRESS.length - 1)}</p>
                <p className="mb-1">TokenID  {item?.nft_id}</p>
                <p className="mb-1">Blockchain &nbsp;&nbsp;&nbsp;&nbsp; Binance Smart Chain</p>

                <h4 className="mt-5">
                  Properties
                </h4>
                <div className="grids">
                  <div className="up">
                    {item?.properties?.map((property, index) => {
                      return(
                        <div className="g1 propertiesCard" key={`${property.key}-${index}`}>
                          {/* <img className="mb-3" src={propertyIco} alt="propertyIco" /> */}
                          <div className="property">{property.key}</div>
                          <div className="value">{property.value}</div>
                          {/* <div className="perc">4% have this property</div> */}
                        </div>  
                      );
                    })}
                  </div>
                  
                </div>
              </div>
            </div>

          {/* collection */}
          <div className="thisColectionSec">
                <h2 className="heading">Also from this Collection</h2>
                <div className="cName">{item?.collection_id?.name}</div>

                <div className="nftCardMain">
                      <div className="row">
                        {moreFromCol.map((itm, index) => {
                          return(
                            <div className="col-sm-6 col-lg-4 col-xxl-3 col-xl-4 mb-4" key={index}>
                              <NFTCARD nft_id={itm?.nft_id} item_id={itm?._id} updateMoreFromItems={updateMoreFromItems} likedBy={itm?.likedBy} image={`${process.env.REACT_APP_BASE_URL}/${itm.image_local}`} name={itm.name} cName={item?.collection_id?.name} price={itm?.price} />
                            </div>
                          );
                        })}                          
                        {/* {NFTS.length == 0 &&
                          <h1 className="noDAtaHead">No Data Found</h1>
                        } */}
                      </div>
                      <div className="row text-align-center">
                        <div className='itemBtnDiv my-4 text-center'>
                        
                            <button className='btn  mkof themeBtn' onClick={() => navigate(`/collection/${item?.collection_id?.trimmedname}`)}  > View All</button>
                        </div>
                      </div>
                    </div>
              </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
              <div className="editProfileModal">
                <div className="closeDiv" onClick={handleClose}>
                  <img src={closeIco} alt="closeIco" />
                </div>
                  <h2>Edit Profile</h2>
              
                  <div className="eForm checkForm">
                    <div className="r1">
                      <p>Item</p>
                      <p>Subtotal</p>
                    </div>
                    <div className="checkCArd">
                      <div className="itemDiv">
                            <div className="logo">
                                <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item?.image_local}`} alt="logo" />
                            </div>
                            <div className="price">
                                <div className="bath">{item?.name}</div>
                                <div className="dollar">WinWinWin</div>
                            </div>
                        </div>
                        <div className="profileDiv">
                            {/* <div className="logo">
                                <img className="img-fluid" src={currencies[item?.currency].icon} alt="logo" />
                            </div> */}
                            <div className="price">
                                <div className="bath">
                                  <img className="img-fluid" src={currencies[item?.currency].icon} alt="logo" /> {item?.price} {currencies[item?.currency].symbol}
                                </div>
                                <div className="dollar" style={{textAlign: 'end'}}>~ ${280*(item?.price ? parseFloat(item?.price) : 0)}</div>
                            </div>
                        </div>
                      </div>
                      <div className="r1 mb-4">
                        <p className='mb-0'>Total</p>
                        <div className="profileDiv">
                            <div className="logo">
                            </div>
                            <div className="price">
                                <div className="bath color-white">
                                  <img className="img-fluid" src={currencies[item?.currency].icon} alt="logo" /> {item?.price} {currencies[item?.currency].symbol}
                                </div>
                                <div className="dollar color-white" style={{textAlign: 'end'}}>~ ${280*(item?.price ? parseFloat(item?.price) : 0)}</div>
                            </div>
                        </div>
                    </div>
                    
                  
                      <div className="btnMain py-4">
                          <Button className='Cancel'  onClick={handleClose}>
                              Cancel
                          </Button>
                          <Button className='save themeBtn' onClick={handleShowPurchase}>
                              Checkout
                          </Button>
                      </div>
                  </div>
              </div>
          </Modal.Body>
      
        </Modal>

        <Modal show={showPurchase} onHide={handleClosePurchase}>
          
          <Modal.Body>
              <div className="editProfileModal">
                <div className="closeDiv" onClick={handleClosePurchase}>
                  <img src={closeIco} alt="closeIco" />
                </div>
                <div className="purchaseMain">
                  <div className="check">
                    <img className='img-fluid' src={checkMark} alt="checkMark" />
                  </div>
                  <div className="purchase mb-3">Your purchase has processed!</div>
                  <div className="desc">You just purchased {item?.name}</div>
                </div>
              </div>
          </Modal.Body>
      
        </Modal>

        
        <Modal show={bidModal} onHide={handleCloseBidModal}>
          <Modal.Body>
              <div className="editProfileModal">
                <div className="closeDiv" onClick={handleCloseBidModal}>
                  <img src={closeIco} alt="closeIco" />
                </div>
                <div className="purchaseMain">
                  <div style={{
                  textAlign: 'left'
                  }}>
                    <label style={{fontSize: '18px'}}>Enter Amount</label> <br/>
                    <input type="number" id="bid-amt" style={{
                      width: '100%',
                      background: 'transparent',
                      border: '0px',
                      borderBottom: '1px solid grey'
                    }} />
                  </div>
                  <div style={{textAlign:'right'}}>
                    <button onClick={bidItem} style={{
                        marginTop: '10px',
                        border: '0px',
                        color: 'grey',
                        fontWeight: '900',
                        borderRadius: '10px',
                        padding: '3px 41px'
                      }}>Bid</button>
                  </div>

                </div>
              </div>
          </Modal.Body>
      
        </Modal>
        <Loader loader={loader} msg={loaderMsg} />
      </section>
    ) 
  }
}

const NFTCARD = ({ nft_id, item_id, updateMoreFromItems, likedBy, image, name, cName, price }) =>{
  const authedUser = useSelector(state => state.authedUser);
  const navigate = useNavigate();

  return (
    // <Link to={`/detail`} className="td-none">
    <div className="artworkCard h-100">
      <div className="favorite" onClick={() => {
        likeNft(item_id, authedUser)
        .then(r => {
          if (r.success){
            updateMoreFromItems(r.item);
          }
        });
      }}>
        {likedBy.includes(authedUser.authedUser?._id) ? <i class="fas fa-heart me-2 red-color"></i> : <i class="far fa-heart me-2 red-color"></i>}
      </div>
      <div className="imgDiv">
        <img src={image} onClick={() => navigate(`/detail/${nft_id}`)} alt="artWork" />
      </div>
      <div className="content">
        <div className="nameMain">
          <h6>{name}</h6>
          <p>{cName}</p>
        </div>
        <div className="profileDiv">
          <div className="imgDiv">
            <img src={artist} alt="artist" />
          </div>
          <div className="amount">
            <div className="pri">Price</div>
            <div className="am">{price}</div>
          </div>
        </div>
      </div>
    </div>
  // </Link>
  )
}


