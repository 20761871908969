import React ,{useEffect, useState} from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const getFeatureBlocked = (feature, cb) => {
  fetch(`${process.env.REACT_APP_BASE_URL}/users/get-feature-blocked/${feature}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }).then(r => r.json()).then(res => {
    console.log(res);
    if (res.success){
      cb(res.users);
    }
  });
}

export default function BlockedUsers(){
  let [blockedUsers, setBlockedUsers] = useState([]);
  let [blockedUsersCreatingNft, setBlockedUsersCreatingNft] = useState([]);
  let [blockedUsersCreatingColl, setBlockedUsersCreatingColl] = useState([]);
  let [blockedUsersMinting, setBlockedUsersMinting] = useState([]);
  let [blockedUsersListing, setBlockedUsersListing] = useState([]);
  let [blockedUsersBidding, setBlockedUsersBidding] = useState([]);
  let [blockedUsersEndAuction, setBlockedUsersEndAuction] = useState([]);
  let [blockedUsersBuying, setBlockedUsersBuying] = useState([]);
  let [blockedUsersWithdrawBid, setBlockedUsersWithdrawBid] = useState([]);
  let [blockedUsersCancelListing, setBlockedUsersCancelListing] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-all-blocked`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json()).then(res => {
      console.log(res);
      if (res.success){
        setBlockedUsers(res.users);
      }
    });
    getFeatureBlocked('creatingNft', setBlockedUsersCreatingNft);
    getFeatureBlocked('creatingColl', setBlockedUsersCreatingColl);
    getFeatureBlocked('minting', setBlockedUsersMinting);
    getFeatureBlocked('listing', setBlockedUsersListing);
    getFeatureBlocked('buying', setBlockedUsersBuying);
    getFeatureBlocked('bidding', setBlockedUsersBidding);
    getFeatureBlocked('withdrawBid', setBlockedUsersWithdrawBid);
    getFeatureBlocked('cancelListing', setBlockedUsersCancelListing);
    getFeatureBlocked('endAuction', setBlockedUsersEndAuction);
  }, []);

  const addBlocked = async () => {
    let details = document.getElementById('blocked-user-details').value;
    if (details){
      fetch(`${process.env.REACT_APP_BASE_URL}/users/add-blocked`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({address: details})
      }).then(r => r.json())
      .then(res => {
        console.log(res);
        if (res.success){
          setBlockedUsers(blockedUsers.concat([res.user]));
        }
        else{
          alert(res.msg);
        }
      })
    }
    else{
      alert('Format incorrect');
    }
  }

  const addBlockedFeature = async (key, cb) => {
    let details = document.getElementById(`blocked-user-${key}`).value;
    if (details){
      fetch(`${process.env.REACT_APP_BASE_URL}/admin/update-status-user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({status: false, address: details, key})
      }).then(r => r.json())
      .then(res => {
        console.log(res);
        if (res.success){
          cb(res.users);
          document.getElementById(`blocked-user-${key}`).value = '';
        }
        else{
          alert(res.msg);
        }
      })
    }
    else{
      alert('Format incorrect');
    }
  }

  const removeBlocked = async (id) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/remove-blocked`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({address: id})
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        setBlockedUsers(blockedUsers.filter(i => i.address !== id));
      }
      else{
        alert(res.msg);
      }
    })
  }

  const removeBlockedFeature = async (address, key, cb) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/update-status-user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({status: true, address, key})
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        cb(res.users);
      }
      else{
        alert(res.msg);
      }
    })
  }
 
  return(
    <div className="container mt-3">
        <h2 className="mb-4 text-center text-white"  style={{fontFamily: 'Gotham-Font-Navbar'}}>Blocked Users</h2>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>All Features</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-details" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={addBlocked} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Blocked Users</h4>
          {blockedUsers.map(c => (
            <FormGroup check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c.address}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlocked(c.address)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Creating NFT</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-creatingNft" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('creatingNft', setBlockedUsersCreatingNft)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from creating NFT</h4>
          {blockedUsersCreatingNft.map(c => (
            <FormGroup key={`creating-nft-${c}`} check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'creatingNft', setBlockedUsersCreatingNft)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Creating Collection</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-creatingColl" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('creatingColl', setBlockedUsersCreatingColl)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from minting</h4>
          {blockedUsersCreatingColl.map(c => (
            <FormGroup key={`creating-coll-${c}`} check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'creatingColl', setBlockedUsersCreatingColl)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Minting</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-minting" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('minting', setBlockedUsersMinting)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from minting</h4>
          {blockedUsersMinting.map(c => (
            <FormGroup key={`minting-${c}`} check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'minting', setBlockedUsersMinting)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Listing</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-listing" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('listing', setBlockedUsersListing)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from listing</h4>
          {blockedUsersListing.map(c => (
            <FormGroup check key={`listing-${c}`} style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'listing', setBlockedUsersListing)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Bidding</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-bidding" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('bidding', setBlockedUsersBidding)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from bidding</h4>
          {blockedUsersBidding.map(c => (
            <FormGroup check key={`bidding-${c}`} style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'bidding', setBlockedUsersBidding)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>End Auction</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-endAuction" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('endAuction', setBlockedUsersEndAuction)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from ending auction</h4>
          {blockedUsersEndAuction.map(c => (
            <FormGroup check key={`endAuction-${c}`} style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'endAuction', setBlockedUsersEndAuction)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Buying</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-buying" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('buying', setBlockedUsersBuying)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from buying</h4>
          {blockedUsersBuying.map(c => (
            <FormGroup key={`buying-${c}`} check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'buying', setBlockedUsersBuying)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Withdraw Bid</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-withdrawBid" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('withdrawBid', setBlockedUsersWithdrawBid)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from withdrawing bid</h4>
          {blockedUsersWithdrawBid.map(c => (
            <FormGroup key={`withdrawBid-${c}`} check style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'withdrawBid', setBlockedUsersWithdrawBid)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
      
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Cancel Listing</h3>
        </div>

        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter user address:</label><br/>
          <input type="text" id="blocked-user-cancelListing" className="collectionFilter w-100 mb-2" placeholder='Ex: 0x1dE9CfD2c41c84d202309dda4285616583FB56fD' ></input>
          <button onClick={() => addBlockedFeature('cancelListing', setBlockedUsersCancelListing)} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Block User</button>
          <h4 className='mt-3'>Users blocked from cancel listing</h4>
          {blockedUsersCancelListing.map(c => (
            <FormGroup check key={`cancelListing-${c}`} style={{textAlign: 'left'}}>
                <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                    {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                    <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                    <div>{c}</div>
                    <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => removeBlockedFeature(c, 'cancelListing', setBlockedUsersCancelListing)}>Remove</button>
                </div>
            </FormGroup>
          ))}
        </div>
      </div>
      <hr/>
    </div>
  );
}
