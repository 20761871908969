import winIco from '../images/win-small.svg';
import bnbIco2 from '../images/binance20by20.png';

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const currencies = {
  'win': {
    symbol: 'WWIN',
    icon: winIco,
    decimals: 18,
    address: "0x7311BAd80A3892065f8790eCe1cF7AB0D0CEA168"
  },
  'bnb': {
    symbol: 'BNB',
    icon: bnbIco2,
    decimals: 18,
    address: ZERO_ADDRESS
  }
};
