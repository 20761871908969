import React, { useEffect, useState } from "react";
import CollectibleItemCard from "./collectibleCard";

export default function AuctionsTab({col_id}){
  let [auctionItems, setAuctionItems] = useState([]);

  useEffect(() => {
    if (col_id){
      fetch(`${process.env.REACT_APP_BASE_URL}/collections/auction-items/${col_id}`)
      .then((res) => res.json()).then(r => setAuctionItems(r.items))  
    }
  }, [col_id])
  return(
    <>
    {auctionItems.map((item,index)=>{
      return(
        <CollectibleItemCard item={item} />
      )
    })}
    </>
  );
}