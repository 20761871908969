import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { AuthedUser } from './reducers/authedUser';
import { Status } from './reducers/status';

export const ConfigureStore = () => {
    const store = configureStore({
      reducer: {
        authedUser: AuthedUser,
        status: Status
        // collections: Collections,
        // logs: Logs
      },
      middleware: [thunk, logger]  
    });
    return store;
}