import Web3 from 'web3';

let web3;

if (window.ethereum){
  if (parseInt(window.ethereum.chainId) === parseInt(process.env.REACT_APP_CHAIN_ID)){
    web3 = new Web3(window.ethereum);
  }
  else{
    web3 = new Web3(process.env.REACT_APP_NODE_URL_RPC);
  }
}
else{
  web3 = new Web3(process.env.REACT_APP_NODE_URL_RPC);
}
let a = {
  web3: web3
};
export default a;