import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import DeleteCollectionModal from './DeleteCollectionModal/deleteCollectionModal';

export default function UpdateCollections(){
  const [collections, setCollections] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCol, setSelectedCol] = useState({});

  const getAllCollections = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-colls`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        setCollections(res.collections);
      }
    });
  }

  const updateBanned = (col_id) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/update-col`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ col_id })
    }).then(res => res.json())
    .then(r => {
      if (r.success){
        setCollections(collections.map(c => c._id !== col_id ? c : r.collection));
      }
    })
  }

  const deleteCollection = (col_id) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/delete-col`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ col_id })
    }).then(res => res.json())
    .then(r => {
      if (r.success){
        setCollections(collections.filter(c => c._id !== col_id));
        alert('Deleted Collection');
      }
      setOpen(false);
      setSelectedCol({});
    })
  }

  useEffect(() => {
    getAllCollections();
  }, []);

  return(
    <div className="container mt-3">
      <h2 className="mb-4 text-center text-white"  style={{fontFamily: 'Gotham-Font-Navbar'}}>Edit/Delete Collections</h2>
      <div className="row text-center text-white">
        {collections.map(c => (
          <FormGroup check style={{textAlign: 'left'}}>
              <div style={{display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px white solid'}} className="custom-control custom-checkbox py-2">
                  {/* <input type="checkbox" className="custom-control-input" defaultChecked={c.featured} id={`featured-nft-${c._id}`}></input>
                  <label className="custom-control-label mb-0 pl-2 text-white" htmlFor={`featured-nft-${c._id}`}>{c.name}</label> */}
                  <div>{c.name}</div>
                  <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => updateBanned(c._id)}>{c.banned ? 'Unban' : 'Ban'}</button>
                  <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => {setSelectedCol(c); setOpen(true)}}>Delete</button>
              </div>
          </FormGroup>
        ))}
      </div>
      {open && <DeleteCollectionModal setCollection={setSelectedCol} collection={selectedCol} open={open} setOpen={setOpen} deleteCol={() => deleteCollection(selectedCol._id)} />}
    </div>

  );
}