import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import { getStatus } from '../../redux/ActionCreators';

const getStatusFeature = (feature, cb) => {
  fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-feature-status/${feature}`).then(r => r.json()).then(res => {
    console.log(res);
    if (res.success){
      cb(res.status);
    }
  });
}

export default function BlockApp(){
  let [status, setStatus] = useState(true);
  let [statusCreatingNFT, setStatusCreatingNFT] = useState(true);
  let [statusCreatingColl, setStatusCreatingColl] = useState(true);
  let [statusMinting, setStatusMinting] = useState(true);
  let [statusListing, setStatusListing] = useState(true);
  let [statusBidding, setStatusBidding] = useState(true);
  let [statusEndAuction, setStatusEndAuction] = useState(true);
  let [statusBuying, setStatusBuying] = useState(true);
  let [statusWithdrawBid, setStatusWithdrawBid] = useState(true);
  let [statusCancelListing, setStatusCancelListing] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getStatusFeature('all', setStatus);
    getStatusFeature('creatingNft', setStatusCreatingNFT);
    getStatusFeature('creatingColl', setStatusCreatingColl);
    getStatusFeature('minting', setStatusMinting);
    getStatusFeature('listing', setStatusListing);
    getStatusFeature('bidding', setStatusBidding);
    getStatusFeature('endAuction', setStatusEndAuction);
    getStatusFeature('buying', setStatusBuying);
    getStatusFeature('withdrawBid', setStatusWithdrawBid);
    getStatusFeature('cancelListing', setStatusCancelListing);
  }, []);

  const changeStatus = (s, key, cb) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/update-status`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({status: s, key})
    }).then(r => r.json()).then(res => {
      console.log(res);
      if (res.success){
        cb(res.status);
      }
    })
  }
 
  return(
    <div className="container mt-3">
        <h2 className="mb-4 text-center text-white"  style={{fontFamily: 'Gotham-Font-Navbar'}}>Block Features</h2>
      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>All Features</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {status ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!status, 'all', setStatus)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Create NFT</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusCreatingNFT ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusCreatingNFT, 'creatingNft', setStatusCreatingNFT)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Create Collection</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusCreatingColl ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusCreatingColl, 'creatingColl', setStatusCreatingColl)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Minting</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusMinting ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusMinting, 'minting', setStatusMinting)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Listing</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusListing ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusListing, 'listing', setStatusListing)}>Change Status</button>
        </div>
      </div>
      
      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Bidding</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusBidding ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusBidding, 'bidding', setStatusBidding)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Buying</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusBuying ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusBuying, 'buying', setStatusBuying)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>End Auction</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusEndAuction ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusEndAuction, 'endAuction', setStatusEndAuction)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Withdraw Bid</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusWithdrawBid ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!statusWithdrawBid, 'withdrawBid', setStatusWithdrawBid)}>Change Status</button>
        </div>
      </div>

      <hr/>

      <div className="row text-center text-white">
        <div className='col-12 text-left'>
          <h3 style={{fontFamily: 'Gotham-Font-Navbar'}}>Cancel Listing</h3>
        </div>
        <div className="col-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <h4>Status: {statusCancelListing ? "Active" : "Not Active"}</h4>
          <button className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}} onClick={() => changeStatus(!status, 'cancelListing', setStatusCancelListing)}>Change Status</button>
        </div>
      </div>
    </div>
  );
}
