import React ,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

export default function DeleteNFTS(){

  const deleteNFT = () => {
    let token_id = document.getElementById('delete-tokid').value;
    if (token_id){
      fetch(`${process.env.REACT_APP_BASE_URL}/items/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({token_id})
      }).then(r => r.json())
      .then(res => {
        alert(res.msg);
      });
    }
    else{
      alert('Please enter token id');
    }
  }
 
  return(
    <div className="container mt-3">
        <h2 className="mb-4 text-center text-white"  style={{fontFamily: 'Gotham-Font-Navbar'}}>Delete NFTs</h2>
      <div className="row text-center text-white">
        <div className="col-12 col-sm-6 offset-sm-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <label>Enter token id:</label><br/>
          <input type="text" id="delete-tokid" className="collectionFilter w-100 mb-2" placeholder='Ex: 1192' ></input>
          <button onClick={deleteNFT} className="py-2 add-category-btn" style={{cursor:'pointer',paddingLeft:'20px'}}>Delete NFTs</button>
        </div>
      </div>
    </div>
  );
}
