import web3 from '../web3';
import NFTMinterABI from '../ethereum/winwinCollectionV1.json';
import MarketplaceABI from '../ethereum/WinwinMarketplaceV1.json';
import { currencies } from './constants';
import { toast } from 'react-toastify';

export const mintNFT = async (royalty, address, item_id, cb) => {
  let mintingAccess = await mintingAllowed(address.toLowerCase());
  if (mintingAccess){
    let aa = new web3.web3.eth.Contract(NFTMinterABI, process.env.REACT_APP_MINTER_CONTRACT_ADDRESS);
    try{
      let minted = await aa.methods.createItem(parseInt(royalty)*100).send({
        from: address
      });
      console.log(minted);
      console.log(minted.events.Transfer.returnValues[2]);
      fetch(`${process.env.REACT_APP_BASE_URL}/items/minted`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(address.toLowerCase())
        },
        body: JSON.stringify({nft_id: minted.events.Transfer.returnValues[2], item_id: item_id, txHash: minted.transactionHash})
      }).then(r => r.json()).then(r => {
        console.log(r);
        if (r.success){
          cb(r);
        }
      });
    }
    catch(e){
      console.log(e);
      toast('Failed to mint', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      alert('Failed to mint');
    }
  }
  else{
    toast('This feature is blocked. Contact admin', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark'
    });
  }
}

export const withdrawBidMethod = async (address, bid, successCb, errorCb) => {
  let withdrawAccess = await withdrawBidAllowed(address.toLowerCase());
  if (withdrawAccess){
    let aa = new web3.web3.eth.Contract(MarketplaceABI, process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
    try{
      let withdrawn = await aa.methods.withdrawPrevBid(bid.item_id.marketplace_id, currencies[bid.currency].address).send({
        from : address
      });
      fetch(`${process.env.REACT_APP_BASE_URL}/bids/withdraw`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(address.toLowerCase())
        },
        body: JSON.stringify({item_id: bid.item_id._id, txHash: withdrawn.transactionHash, currency: bid.currency})
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          successCb(res);
        }
        else{
          errorCb(res);
        }
      })
    }
    catch(e){
      console.log(e);
      errorCb({success: false, msg: 'This feature is blocked. Contact admin'});
    }
  }
  else{
    errorCb();
  }

}

export const isLiked = (id, arr) => {
  if (id){
    return arr.includes(id)
  }
  else{
    return false;
  }
}

export const likeNft = async (item_id, authedUser) => {
  if (authedUser.authedUser.address){
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/likeitem/${item_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(authedUser.authedUser.address.toLowerCase())}`
      },
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        console.log(res);
        return res;
      }
    })
  }
  else{
    toast('Connect Wallet', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark'
    });
    // alert('Connect Wallet');
    return {success: false};
  }
}

export const transferNFTMethod = async (transferAddress, owner, collection_address, nft_id, item_id, cb) => {
  let ERC721 = new web3.web3.eth.Contract(NFTMinterABI, collection_address);
  try{
    let r = await ERC721.methods.transferFrom(owner, transferAddress, nft_id).send({
      from: owner
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/items/transferred`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(owner.toLowerCase())
      },
      body: JSON.stringify({item_id: item_id, txHash: r.transactionHash, newOwner: transferAddress})
    }).then(r => r.json()).then(r => {
      console.log(r);
      if (r.success){
        cb(r);
      }
    });
  }
  catch(e){
    console.log(e);
    toast('Failed to transfer', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark'
    });
    alert('Failed to mint');
  }
}

export const creatingNftAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-create-nft-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const creatingCollAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-create-coll-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const listingAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-listing-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const mintingAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-minting-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const biddingAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-bidding-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const endingAuctionAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-end-auction-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const buyingAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-buying-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const withdrawBidAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-withdraw-bid-status/${userId}`).then(r => r.json()).then(r => r.success);
}

export const cancelListingAllowed = async (userId) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-cancel-listing-status/${userId}`).then(r => r.json()).then(r => r.success);
}
