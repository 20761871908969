import { useState, useEffect } from "react"
// import api from "../../api/index.js";
import { Loader } from "../loader";
import user1 from "../../images/user1.jpg";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import artWork from "../../images/nftArtImg.png"
import artist from "../../images/artist.png"
import downArrow from "../../images/downArrow.svg"
import artWork2 from "../../images/artWork2.png"
import artWork3 from "../../images/artWork3.png"
import artWork4 from "../../images/artWork4.png"
import bluePinkEffect from "../../images/bluePinkEffect.png"
import "./explore.css"
import { useSelector } from "react-redux";
import { likeNft } from "../../helpers/helpers";
import { toast } from "react-toastify";
import { currencies } from "../../helpers/constants";

const number = 10000000000000;

export function MarketPlace() {
  const  [searchParams, setSearchParams] = useSearchParams();
  const authedUser = useSelector((state) => state.authedUser);

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState(2);

  const [total, setTotal] = useState(true);
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);

  const sortFilter = (a, b) => {
    // Price H L
    // Price L H
    // Latest
    // Old
    
    if (sort === 0){
      return parseFloat(b.price) - parseFloat(a.price);
    }
    else if (sort === 1){
      return parseFloat(a.price) - parseFloat(b.price);
    }
    else if (sort === 2){
      console.log("2", new Date(b.updatedAt), new Date(a.updatedAt), new Date(b.updatedAt) - new Date(a.updatedAt))
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    }
    else if (sort === 3){
      console.log("3", new Date(b.updatedAt), new Date(a.updatedAt), new Date(b.updatedAt) - new Date(a.updatedAt))
      return new Date(a.updatedAt) - new Date(b.updatedAt);      
    }
  }

  const [filters, setFilters] = useState({
    status: [],
    categories: [],
    collections: [],
    minPrice: 0,
    maxPrice: number,
    currency: []
  });

  const updateItem = (item) => {
    setItems(items.map(m => {
      if (m._id === item._id){
        return {...m, likedBy: item?.likedBy};
      }
      else{
        return m;
      }
    }))
  }

  const currencyChange = (e, item) => {
    let searchP = {};
    if (e){
      setFilters({...filters, currency: filters.currency.includes(item) ? filters.currency : filters.currency.concat([item])});
      searchP['currency'] = filters.currency.includes(item) ? filters.currency.join('-') : filters.currency.concat([item]).join('-');
    }
    else{
      setFilters({...filters, currency: filters.currency.filter(i => i !== item)});
      searchP['currency'] = filters.currency.filter(i => i !== item).join('-');
    }
    if (!searchP['currency']) { delete searchP['currency'] };
    if (filters.status.length > 0) {searchP['status'] = filters.status.join('-')};
    if (filters.minPrice > 0) {searchP['minPrice'] = filters.minPrice};
    if (filters.maxPrice < number) {searchP['maxPrice'] = filters.maxPrice};
    if (filters.categories.length > 0) {searchP['categories'] = filters.categories.join('-')};
    if (filters.collections.length > 0) {searchP['collections'] = filters.collections.join('-')};
    console.log(searchP);
    setSearchParams(searchP);
  }
  const statusChange = (e, item) => {
    let searchP = {};
    if (e){
      setFilters({...filters, status: filters.status.includes(item) ? filters.status : filters.status.concat([item])});
      searchP['status'] = filters.status.includes(item) ? filters.status.join('-') : filters.status.concat([item]).join('-');
    }
    else{
      setFilters({...filters, status: filters.status.filter(i => i !== item)});
      searchP['status'] = filters.status.filter(i => i !== item).join('-');
    }
    if (!searchP['status']) { delete searchP['status'] };
    if (filters.currency.length > 0) {searchP['currency'] = filters.currency.join('-')};
    if (filters.minPrice > 0) {searchP['minPrice'] = filters.minPrice};
    if (filters.maxPrice < number) {searchP['maxPrice'] = filters.maxPrice};
    if (filters.categories.length > 0) {searchP['categories'] = filters.categories.join('-')};
    if (filters.collections.length > 0) {searchP['collections'] = filters.collections.join('-')};
    console.log(searchP);
    setSearchParams(searchP);
  }
  const categoriesChange = (e, item) => {
    let searchP = {};
    if (e){
      setFilters({...filters, categories: filters.categories.includes(item) ? filters.categories : filters.categories.concat([item])});
      searchP['categories'] = filters.categories.includes(item) ? filters.categories.join('-') : filters.categories.concat([item]).join('-');
    }
    else{
      setFilters({...filters, categories: filters.categories.filter(i => i !== item)});
      searchP['categories'] = filters.categories.filter(i => i !== item).join('-');
    }
    if (!searchP['categories']) { delete searchP['categories'] };
    if (filters.minPrice > 0) {searchP['minPrice'] = filters.minPrice};
    if (filters.maxPrice < number) {searchP['maxPrice'] = filters.maxPrice};
    if (filters.currency.length > 0) {searchP['currency'] = filters.currency.join('-')};
    if (filters.status.length > 0) {searchP['status'] = filters.status.join('-')};
    if (filters.collections.length > 0) {searchP['collections'] = filters.collections.join('-')};
    console.log(searchP);
    setSearchParams(searchP);
  }
  const collectionsChange = (e, item) => {
    let searchP = {};
    if (e){
      setFilters({...filters, collections: filters.collections.includes(item) ? filters.collections : filters.collections.concat([item])});
      searchP['collections'] = filters.collections.includes(item) ? filters.collections.join('-') : filters.collections.concat([item]).join('-');
    }
    else{
      setFilters({...filters, collections: filters.collections.filter(i => i !== item)});
      searchP['collections'] = filters.collections.filter(i => i !== item).join('-');
    }
    if (!searchP['collections']) { delete searchP['collections'] };
    if (filters.minPrice > 0) {searchP['minPrice'] = filters.minPrice};
    if (filters.maxPrice < number) {searchP['maxPrice'] = filters.maxPrice};
    if (filters.currency.length > 0) {searchP['currency'] = filters.currency.join('-')};
    if (filters.status.length > 0) {searchP['status'] = filters.status.join('-')};
    if (filters.categories.length > 0) {searchP['categories'] = filters.categories.join('-')};
    console.log(searchP);
    setSearchParams(searchP);
  }

  const showMore = () => {
    setIsLoading(true);
    let str = '';
    if (filters.status){
      str = `&status=${filters.status}`;
    }
    if (filters.currency.length > 0){
      str += `&currency=${filters.currency}`;
    }

    if (filters.categories.length > 0){
      str += `&categories=${filters.categories}`;
    }

    if (filters.collections.length > 0){
      str += `&collections=${filters.collections}`;
    }

    if (filters.minPrice > 0){
      str += `&minPrice=${filters.minPrice}`;
    }

    if (filters.maxPrice > 0){
      str += `&maxPrice=${filters.maxPrice}`;
    }
    fetch(`${process.env.REACT_APP_BASE_URL}/items/onsale?page=${page}&limit=24${str}`)
    .then(res => res.json())
    .then(r => {
      console.log(r)
      if (r.success){
        setItems(items.concat(r.items));
      }
      setIsLoading(false);
    });
  }

  const updatePrice = () => {
    let searchP = {};
    let min = parseFloat(document.getElementById('minPrice').value) ? parseFloat(document.getElementById('minPrice').value) : 0;
    let max = parseFloat(document.getElementById('maxPrice').value) ? parseFloat(document.getElementById('maxPrice').value) : number;
    if (min >= max){
      toast('Minimum Price should be less than Maximum Price', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      // alert('Minimum Price should be less than Maximum Price');
    }
    else{
      setFilters({...filters, minPrice: min, maxPrice: max});
      if (filters.categories.length > 0) {searchP['categories'] = filters.categories.join('-')};
      if (filters.collections.length > 0) {searchP['collections'] = filters.collections.join('-')};
      if (filters.currency.length > 0) {searchP['currency'] = filters.currency.join('-')};
      if (filters.status.length > 0) {searchP['status'] = filters.status.join('-')};
      if (min > 0) {searchP['minPrice'] = min};
      if (max < number) {searchP['maxPrice'] = max};
      setSearchParams(searchP);
    }
  }

  const getCategories = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`).then(r => r.json())
    .then(r => {
      if (r.success){
        setCategories(r.categories);
      }
    })
  }
  const getCollections = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/collections/name`).then(r => r.json())
    .then(r => {
      if (r.success){
        setCollections(r.collections);
      }
    })
  }

  useEffect(() => {
    let status = searchParams.get('status') ? searchParams.get('status').split('-') : [];
    let currency = searchParams.get('currency') ? searchParams.get('currency').split('-') : [];
    let categories = searchParams.get('categories') ? searchParams.get('categories').split('-') : [];
    let collections = searchParams.get('collections') ? searchParams.get('collections').split('-') : [];
    let minPrice = searchParams.get('minPrice') ? parseFloat(searchParams.get('minPrice')) : 0;
    let maxPrice = searchParams.get('maxPrice') ? parseFloat(searchParams.get('maxPrice')) : number;
    setFilters({ ...filters, status, categories, minPrice, maxPrice, collections, currency });
    getCategories();
    getCollections();
  }, []);

  useEffect(() => {
    console.log('f items, searchParams', searchParams.get(''));
    let str = '';
    if (filters.status.length > 0){
      str += `&status=${filters.status}`;
    }
    if (filters.currency.length > 0){
      str += `&currency=${filters.currency}`;
    }
    // else if (searchParams.get('status')){
    //   str += `&status=${searchParams.get('status').split('-')}`;
    // }

    if (filters.categories.length > 0){
      str += `&categories=${filters.categories}`;
    }
    // else if (searchParams.get('status')){
    //   str += `&categories=${searchParams.get('categories').split('-')}`;
    // }

    if (filters.collections.length > 0){
      str += `&collections=${filters.collections}`;
    }

    if (filters.minPrice > 0){
      str += `&minPrice=${filters.minPrice}`;
    }
    // else if (searchParams.get('minPrice')){
    //   str += `&minPrice=${searchParams.get('minPrice')}`;
    // }

    if (filters.maxPrice > 0){
      str += `&maxPrice=${filters.maxPrice}`;
    }
    // else if (searchParams.get('maxPrice')){
    //   str += `&maxPrice=${searchParams.get('maxPrice')}`;
    // }

    fetch(`${process.env.REACT_APP_BASE_URL}/items/onsale?page=0&limit=24${str}`)
    .then(res => res.json())
    .then(r => {
      console.log(r)
      if (r.success){
        setItems(r.items);
      }
      setIsLoading(false);
      setPage(1);
    })
    fetch(`${process.env.REACT_APP_BASE_URL}/items/total?page=0&limit=24${str}`).then(r => r.json())
    .then(r => {
      console.log(r);
      if (r.success){
        setTotal(r.total);
      }
    })
  }, [filters]);


  return (
    <section className="marketPlaceMain">
      <img className="effect1" src={bluePinkEffect} alt="bluePinkEffect" />
      <img className="effect2" src={bluePinkEffect} alt="bluePinkEffect" />
  
      <div className="container mkContainer">
        <div className="marketMain">
            <div className="row">
              <div className=" col-md-3 col-xl-4 col-xxl-3">
                <div className="filterMain">
                  <div className="filSidebar mb-4">
                    <div className="dropDownCard heading mb-2">
                      <button className='title btn' >Filter <span className="bb-1" style={{ float: "right" }}>Clear all</span></button>
                    </div>
                    <div className="dropDownCard heading mb-2">
                      <button 
                      className='title btn ' type="button" data-bs-toggle="collapse" data-bs-target="#contentDropDown0" aria-expanded="false" aria-controls="contentDropDown0"
                      >Status <span style={{ float: "right" }}> <img src={downArrow} alt="downArrow" /></span></button>
                        <div className="collapse show" id="contentDropDown0">
                            <div className="card-body">
                              <button className='title btn'>On Sale
                                <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('status') ? searchParams.get('status').includes("1") : false} onChange={(e) => statusChange(e.target.checked, "1")} type="checkbox" name="vehicle1" value="Bike" /> </span>
                              </button>
                              <button className='title btn' >On Auction
                                <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('status') ? searchParams.get('status').includes("2") : false} onChange={(e) => statusChange(e.target.checked, "2")} type="checkbox" name="vehicle1" value="Bike" /> </span>
                              </button>
                            </div>
                        </div>
                    </div>
                    <div className="dropDownCard heading mb-2">
                      <button 
                      className='title btn ' type="button" data-bs-toggle="collapse" data-bs-target="#contentDropDown4" aria-expanded="false" aria-controls="contentDropDown0"
                      >Currency <span style={{ float: "right" }}> <img src={downArrow} alt="downArrow" /></span></button>
                        <div className="collapse show" id="contentDropDown4">
                            <div className="card-body">
                              {Object.keys(currencies).map((c, i) => {
                                return(
                                  <button className='title btn'>{currencies[c].symbol}
                                    <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('currency') ? searchParams.get('currency').includes(c) : false} onChange={(e) => currencyChange(e.target.checked, c)} type="checkbox" name="vehicle1" value="Bike" /> </span>
                                  </button>
                                );
                              })}
                              {/* <button className='title btn'>BNB
                                <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('currencies') ? searchParams.get('currencies').includes("1") : false} onChange={(e) => statusChange(e.target.checked, "1")} type="checkbox" name="vehicle1" value="Bike" /> </span>
                              </button>
                              <button className='title btn' >WWIN
                                <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('currencies') ? searchParams.get('currencies').includes("2") : false} onChange={(e) => statusChange(e.target.checked, "2")} type="checkbox" name="vehicle1" value="Bike" /> </span>
                              </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="filSidebar heading dropDownCard mb-2">
                      <button 
                      className='title btn font-bold' type="button" data-bs-toggle="collapse" data-bs-target="#contentDropDown1" aria-expanded="false" aria-controls="contentDropDown1"
                      >Price <span style={{ float: "right" }}> <img src={downArrow} alt="downArrow" /></span></button>
                        <div className="collapse show" id="contentDropDown1">
                            <div className="card-body">
                              {/* <div className="price">Price in BATH</div> */}
                              <div className="inputDiv">
                                <input id="minPrice" className="form-control me-3" placeholder="Min" type="number" />
                                <input id="maxPrice" className="form-control " placeholder="Max" type="number" />
                              </div>
                              <div className="applyBtn">
                                <button onClick={updatePrice}>Apply</button>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="dropDownCard heading mb-2">
                      <button 
                      className='title btn ' type="button" data-bs-toggle="collapse" data-bs-target="#contentDropDown2" aria-expanded="false" aria-controls="contentDropDown2"
                      >Categories <span style={{ float: "right" }}> <img src={downArrow} alt="downArrow" /></span></button>
                        <div className="collapse show" id="contentDropDown2">
                            <div className="card-body">
                              {categories.map((c, i) => {
                                return(
                                  <button className='title btn' key={`exp-${i}-${c._id}`}>{c.name}
                                    <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('categories') ? searchParams.get('categories').includes(c._id) : false} onChange={(e) => categoriesChange(e.target.checked, c._id)} type="checkbox" name="vehicle1" value="Bike" /> </span>
                                  </button>
                                );
                              })}
                            </div>
                        </div>
                    </div>
                    <div className="dropDownCard heading mb-2">
                      <button 
                      className='title btn ' type="button" data-bs-toggle="collapse" data-bs-target="#contentDropDown3" aria-expanded="false" aria-controls="contentDropDown2"
                      >Collections <span style={{ float: "right" }}> <img src={downArrow} alt="downArrow" /></span></button>
                        <div className="collapse show" id="contentDropDown3">
                            <div className="card-body">
                              {collections.map((c, i) => {
                                return(
                                  <button className='title btn' key={`exp-${i}-${c._id}`}>{c.name}
                                    <span style={{ float: "right" }}> <input defaultChecked={searchParams.get('collections') ? searchParams.get('collections').includes(c._id) : false} onChange={(e) => collectionsChange(e.target.checked, c._id)} type="checkbox" name="vehicle1" value="Bike" /> </span>
                                  </button>
                                );
                              })}
                            </div>
                        </div>
                    </div>
                  </div>
                  
                </div>

              </div>

              <div className=" col-md-9 col-xl-8 col-xxl-9">
                <div className="sortByMain">
                  <div className="number">
                    <h1>Marketplace</h1>
                    <p>{total} Results</p>
                  </div>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Sort by
                      <img src={downArrow} alt="downArrow" />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#" onClick={() => setSort(0)}>Price: high to low</a>
                      <a className="dropdown-item" href="#" onClick={() => setSort(1)}>Price: low to high</a>
                      <a className="dropdown-item" href="#" onClick={() => setSort(2)}>Latest</a>
                      <a className="dropdown-item" href="#" onClick={() => setSort(3)}>Old</a>
                    </div>
                  </div>
                </div>
              

                <div className="nftCardMain">
                  <div className="row">
                      {items.length > 0 ? items.sort(sortFilter).map(it => {
                      return(
                        <div key={it._id} className="col-md-6 col-xl-4 mb-4" >
                          <NFTCARD updateItem={updateItem} authedUser={authedUser} item={it} cName={it?.collection_id?.name} />
                        </div>
                      )}) : 
                      <h1 className="noDAtaHead">No Data Found</h1>
                      }
                  </div>
                  {total === items.length ? null : 
                    <div className="row text-align-center">
                      <div className='itemBtnDiv my-5'>
                        <button className='btn showmore themeBtn' onClick={showMore} > Show more</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
        </div>
      </div>
      {isLoading && <Loader loader={isLoading} msg="Loading" />}

    </section>
  )
}

const NFTCARD = ({updateItem, item,cName, authedUser}) =>{
  const navigate = useNavigate();
  
  return (
    // <Link to={} className="td-none">
      <div className="artworkCard h-100">
        <div className="favorite" onClick={() => {
          likeNft(item._id, authedUser).then(res => {
            if (res?.success){
              updateItem(res.item)
            }
          })  
        }}>
          {item?.likedBy.includes(authedUser.authedUser?._id) ? <i class="fas fa-heart me-2 red-color"></i> : <i class="far fa-heart me-2 red-color"></i>}
        </div>
        <div onClick={() => navigate("/detail/"+item.nft_id)} className="imgDiv">
          <img src={`${process.env.REACT_APP_BASE_URL}/${item?.image_local}`} alt="artWork" />
        </div>
        <div className="content">
          <div className="nameMain">
            <h6>{item?.name}</h6>
            <p>{cName}</p>
          </div>
          <div className="profileDiv">
            <div className="imgDiv">
              <img src={artist} alt="artist" />
            </div>
            <div className="amount">
              <div className="pri">Price</div>
              <div className="am">{item?.price} BNB</div>
            </div>
          </div>
        </div>
      </div>
    // </Link>
  )
}

