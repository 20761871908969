import React, { useEffect, useState } from "react";
import CollectibleItemCard from "./collectibleCard";

export default function AllItemsTab({col_id}){
  let [allItems, setAllItems] = useState([]);

  useEffect(() => {
    if (col_id){
      fetch(`${process.env.REACT_APP_BASE_URL}/collections/all-items/${col_id}`)
      .then((res) => res.json()).then(r => setAllItems(r.items));
    }
  }, [col_id]);
  
  if (allItems.length === 0) {
    return(
      <div className="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3 mt-4">
        <h4 className="text-center text-white my-5">No Items Found</h4>
      </div>
    );
  }
  else{
    return(
      <>
        {allItems.map((item,index)=>{
          return(
            <CollectibleItemCard item={item} />
          )
        })}
      </>
    );
  }
}