import * as ActionTypes from './ActionTypes';

export const loginUser = (u) => ({
  type: ActionTypes.LOGGED_IN,
  payload: u
});

export const logoutUser = (u) => ({
  type: ActionTypes.LOGGED_OUT,
  payload: u
});

export const updateUser = (u) => ({
  type: ActionTypes.UPDATE_USER,
  payload: u
});

export const getStatus = () => (dispatch) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/admin/get-status`).then(r => r.json()).then(res => {
    console.log(res);
    if (res.success){
      return dispatch(setStatus(res.status));
    }
  })
}

const setStatus = (s) => ({
  type: ActionTypes.SET_STATUS,
  payload: s
})