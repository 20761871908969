import React, { useEffect, useState } from 'react'
import artWork from "../../images/artWork.png"
import artWork2 from "../../images/artWork2.png"
import artWork3 from "../../images/artWork3.png"
import welcomeEffect from "../../images/welcomeEffect.png"
import coin from "../../images/coin.png"
import "./welcomePage.css"
import { useNavigate } from 'react-router-dom';
import { isLiked, likeNft } from '../../helpers/helpers'
import { useSelector } from 'react-redux';

export const WelcomePage = () => {
  const [mintedItems, setMinted] = useState([]);
  const [trendingItems, setTrending] = useState([]);
  const [officialItems, setOfficial] = useState([]);
  const navigate = useNavigate();

  const updateItem = (item, type) => {
    if (type === 'm'){
      setMinted(mintedItems.map(m => {
        if (m._id === item._id){
          return item;
        }
        else{
          return m;
        }
      }))
    }
    else if (type === 't'){
      setTrending(trendingItems.map(m => {
        if (m._id === item._id){
          return item;
        }
        else{
          return m;
        }
      }))
    }
  }

  useEffect(() => {
    getOfficial();
    getMinted();
  }, []);

  const getTrending = async () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/trending`).then(r => r.json()).then(res => {console.log(res);setTrending(res.items)});
  };
  const getOfficial = async () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/official`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        officialIds: ["6491ebc51e6cf9ad8166058d", "6491e6491e6cf9ad8166052e", "6491e6851e6cf9ad81660551"]
      })
    }).then(r => r.json()).then(res => {console.log(res);setOfficial(res.items)});
  };
  
  const getMinted = async () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/items/minted`).then(r => r.json()).then(res => {console.log(res);setMinted(res.items)});
  };

  // var dummy = [
  //   {
  //     _id:"11assas",
  //     likedBy:"11assas",
  //     nft_id:"11assas",
  //     name:"nft1",
  //     image:artWork,
  //   },
  //   {
  //     _id:"11assas",
  //     likedBy:"11assas",
  //     nft_id:"11assas",
  //     name:"nft1",
  //     image:artWork,
  //   },
  // ]

  return (
    <div className='welcomePage'>
      
      <img className="effect1" src={welcomeEffect} alt="bluePinkEffect" />

        <div className="section1">
            <div className="content">
              <div className="tokenImg">
                <img className='img-fluid' src={coin} alt="" />
              </div>
                <h1 className='welcome'>Welcome to the</h1>
                <h1 className='first'>First SNFT Marketplace,</h1>
                <div className="text">where blockchain technology</div>
                <div className="text">meets ancient Tradition.</div>
            </div>
        </div>
        <div className="newItemSec">
            <div className="container">
                <h1>Official NFTs</h1>
                <div className="nftCardMain">
                        <div className="row">
                          {officialItems.map((item, index) => {
                            return(
                              <div className="col-sm-6 col-lg-4 col-xxl-4 col-xl-4 mb-4" key={`t-${item._id}`}>
                                <NFTCARD updateItem={(it) => updateItem(it, 't')} item_id={item._id} likedBy={item.likedBy} image={`${process.env.REACT_APP_BASE_URL}/${item.image_local}`} nftId={item.nft_id} name={item.name} cName={item?.collection_id?.name} />
                              </div>
                            );
                          })}
                        </div>
                        <div className="row text-align-center">
                        <div className='itemBtnDiv my-4 text-center'>
                            <button className='btn themeBtn mkof' onClick={() => navigate(`/marketplace`)} > View All</button>
                        </div>
                        </div>
                </div>
            </div>
        </div>
        
        <div className="newItemSec">
            <div className="container text-center">
                <h1>ABOUT US</h1>
                <div className="nftCardMain">
                        <div className="row mb-3" style={{fontSize: '20px'}}>
                          <div className='col-12 col-md-10 offset-md-1'>
                            WinWinWin creates and markets the world’s first Sacred NFTs, which are NFT versions of Thai sacred amulets. The sacred amulet industry is more than $1.25 billion annually with some amulets selling for more than $1 million. In addition 7 in 10 Thais wear at least one sacred amulet and the industry includes buyers from around Asia. Each WWin SNFT collection is of the highest quality and limited editions. 
                          </div>
                        </div>
                        <div className="row mb-3" style={{fontSize: '20px'}}>
                          <div className='col-12 col-md-10 offset-md-1'>
                            WWin SNFTs have a high level of authenticity. They are created by world class designers and blessed by top Thai monks. Wearers believe that each has special powers including protection from physical and spiritual dangers and illnesses, good financial fortune or a new romantic partner.
                          </div>
                        </div>
                        <div className="row mb-3" style={{fontSize: '20px'}}>
                          <div className='col-12 col-md-10 offset-md-1'>
                            The Thai amulet industry has not had significant upgrades in more than 100 years. Modernizing the industry using the power of blockchain and NFTs increases the safety and convenience for buyers and sellers. When you include their rarity, WinWinWin SNFTs can become highly collectible. 
                          </div>
                        </div>
                        <div className="row mb-3" style={{fontSize: '20px'}}>
                          <div className='col-12 col-md-10 offset-md-1'>
                            The WWIN SNFT marketplace makes purchasing and trading WWin sacred NFTs easy and secure. Owned and operated by WWin The WWin SNDT marketplace is the only marketplace authorized to trade WWin SNFTs. SNFT owners can list their SNFTs for sale here and buyers can purchase at their leisure, knowing that each SNFT is authentic.
                          </div>
                        </div>
                </div>
            </div>
        </div>

        
        {/* <div className="newItemSec">
            <div className="container">
                <h1>Recently Minted</h1>
                <div className="nftCardMain">
                        <div className="row">
                            {mintedItems.map((item, index) => {
                              return(
                                <div className="col-sm-6 col-lg-4 col-xxl-3 col-xl-4 " key={`m-${item._id}`}>
                                  <NFTCARD updateItem={(it) => updateItem(it, 'm')} item_id={item._id} likedBy={item.likedBy} image={`${process.env.REACT_APP_BASE_URL}/${item.image_local}`} nftId={item.nft_id} name={item.name} cName={item?.collection_id?.name} />
                                </div>
                              );
                            })}
                        </div>
                        <div className="row text-align-center">
                        <div className='itemBtnDiv my-4 text-center'>
                        
                            <button className='btn themeBtn mkof' onClick={() => navigate(`/marketplace`)} > View All</button>
                        </div>
                        </div>
                </div>
            </div>
        </div> */}
        <div className="contractRow">
            <div className="container">
                <div className="content">
                    <p>Contract Address: 0x1FC2C1C218B079F21b6FdD52b07@4fd256c0A176</p>
                    <button className='buyBtn themeBtn'><span>Buy</span> WWIN</button>
                </div>
            </div>
        </div>
    </div>
  )
}


const NFTCARD = ({updateItem, item_id, image, name, cName, nftId, likedBy}) =>{
  const authedUser = useSelector(s => s.authedUser);
  const navigate = useNavigate();  
  
  return (
      <div className="artworkCard h-100">
      <div className="favorite" onClick={() => {
        likeNft(item_id, authedUser).then(res => {
          if (res?.success){
            updateItem(res.item)
          }
        })
      }}>
        {isLiked(authedUser.authedUser._id, likedBy) ? <i class="fas fa-heart me-2 red-color"></i> : <i class="far fa-heart me-2 red-color"></i>}
        {/* <i id="heart"  className="fas fa-heart" style={{color:authedUser.authedUser.address ? (likedBy.includes(authedUser.authedUser._id) ? 'red' : '#162e4a') : '#162e4a'}}></i> */}
      {/* <i class="fal fa-heart me-2"></i> */}
      {likedBy ? likedBy.length : 0}
      </div>
      <div className="imgDiv">
        <img src={image} alt="artWork" />
      </div>
      <div className="content">
        <div className="nameMain">
          <h6>{name}</h6>
          <p>{cName}</p>
        </div>
        <button className=' themeBtn' onClick={() => navigate(`/detail/${nftId}`)}>View</button>
      </div>
    </div>
    )
  }
