import React, { useEffect, useState } from "react";
import FollowerCard from "./followerCard";

export default function FollowersTab ({col_id}) {
  let [followedBy, setFollowedBy] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/collections/followers/${col_id}`)
    .then((res) => res.json()).then(r => setFollowedBy(r.followedBy))
  }, [])
  return(
    <>
    {followedBy.map((item,index)=>{
      return(
        <FollowerCard item={item} />
      )
    })}
    </>
  );
}