
import { create } from 'ipfs-http-client'

var projectId ="1uIYCJAlVi1s5Z9H4OIGIll2tff"
var projectSecret ="a673a22b8a9dc20923e9b671583cc08a"
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
        console.log(auth);
const ipfs =  create({
    host:"ipfs.infura.io",
    port:5001,
    protocol:"https",
    headers: {
        authorization: auth,
        "Access-Control-Allow-Origin": [
            "*"
          ]
        // Origin: 'http://localhost:3000'
    }
})
export default ipfs;    
