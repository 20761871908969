import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import './deleteCollectionModal.css';

const DeleteCollectionModal = ({collection, deleteCol, open, setOpen, setCollection}) => {
  return (
    <div>
      <Modal className="item-hidden-modal" isOpen={open} centered>
        <ModalHeader className="item-hide-header-modal">
          Delete Collection
        </ModalHeader>
        <ModalBody>
          Do you really want to delete {collection.name} permanently?
        </ModalBody>
        <div classNam="d-flex justify-content-center">
          <div className="text-center">
            {/* <p className="mb-2 mb-sm-3">Explore Marketplace.</p> */}
            <Button
              className="understand-btn"
              color="secondary"
              onClick={() => deleteCol()}
            >
              Delete Now
            </Button>
            <Button
              className="understand-btn"
              color="secondary"
              onClick={() => {setOpen(false); setCollection({})}}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DeleteCollectionModal;