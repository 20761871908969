import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ethereumIcon from "../images/eth-diamond-black.png";
import bnbIco from "../images/bnbIco.png";
import searchIco from "../images/searchIco.svg";
import './navbar.css';
import Web3 from "web3";
import web3 from "../web3";
import { logoutUser } from "../redux/ActionCreators";
import { useDispatch, useSelector } from "react-redux";

import logo from "../images/winIco.png";
import menu from "../images/menu.png";
import logout from "../images/logout.png";
import metamask from "../images/metamask.png";
import walletConnect from "../images/walletConnect.png";
import walletIco from "../images/walletIco.png";
import discord from "../images/discord.png";
import Select, { components } from 'react-select';
import debounce from 'lodash.debounce';
import { creatingNftAllowed, creatingCollAllowed } from "../helpers/helpers";

const fetchData = async (query, setLoading, cb) => {
  if (query && query.length){
    setLoading(true);
    console.log('searching');
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/items/searchquery/${query}`)
    .then(r => r.json());
    let arr = [...res.data.nft, ...res.data.user, ...res.data.collection];
    console.log('res', arr);
    setLoading(false);
    cb(arr);
  }
  else{
    cb([]);
  }
}
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/* <EmojiIcon label="Emoji" primaryColor={colourOptions[2].color} /> */}
      <img src={searchIco} className="search_icon" />
    </components.DropdownIndicator>
  );
};
const debouncedFetchData = debounce((query, setLoading, cb) => {
  fetchData(query, setLoading, cb);
}, 500);

const adminTokenValid = async () => {
  if (localStorage.getItem('token')){
    return fetch(`${process.env.REACT_APP_BASE_URL}/admin/check/jwt`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => {
      if (r.ok){
        return r.json();
      }
      else{
        return false;
      }
    }).then(r => r.valid);
  }
  else{
    return false;
  }
}

export const NavbarCustom = ({connectMetamask}) => {
  const dispatch = useDispatch();
  const authedUser = useSelector((s) => s.authedUser);
  const user = useSelector((state) => state.authedUser.authedUser);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [balance, setBalance] = useState(0);
  const [creatingNftStatus, setCreatingNftStatus] = useState(false);
  const [creatingCollStatus, setCreatingCollStatus] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchBalance = async (address) => {
    if (address){
      let b = await web3.web3.eth.getBalance(address);
      setBalance(b);  
    }
  }
  
  const getNftAndCollStatus = async () => {
    let nftAllowed = await creatingNftAllowed(authedUser.authedUser.address);
    let collAllowed = await creatingCollAllowed(authedUser.authedUser.address);
    let validToken = await adminTokenValid();
    console.log(validToken);
    if (validToken){
      setCreatingCollStatus(true);
      setCreatingNftStatus(true);
    }
    else{
      console.log(nftAllowed, collAllowed);
      setCreatingNftStatus(nftAllowed);
      setCreatingCollStatus(collAllowed);  
    }
  }

  useEffect(() => {
    getNftAndCollStatus()
  }, [authedUser.authedUser]);
  
  useEffect(() => {
    debouncedFetchData(searchVal, setLoading, (res) => {
      setSearchResults(res);
    });
  }, [searchVal]);

  useEffect(() => {
    fetchBalance(authedUser.authedUser.address)
  }, [authedUser]);

  const logoutUserReq = () => {
    dispatch(logoutUser(authedUser.authedUser));
    localStorage.removeItem('metamask');
    localStorage.removeItem('walletconnect');
  }

  console.log(authedUser.authedUser.address);

  return (
    <>
      <header className="">
        <nav className="navbar navbar-expand-lg navbar-light custom-nav">
          <div className="container">
            <Link to="/" className="navbar-brand" href="#">
              <img className="img-fluid" src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className="navbar-toggler-icon" /> */}
              <img src={menu} />
            </button>
            <div
              className="collapse navbar-collapse jc-sb"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link style={{fontSize: "24px", fontWeight: "bold"}} className="nav-link active" to="/marketplace">
                    Marketplace
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link className="nav-link active" to="/bath">
                    Bath
                  </Link>
                </li> */}
                {user.address && (
                  creatingNftStatus ? <li className="nav-item">
                    <Link style={{fontSize: "24px", fontWeight: "bold"}} className="nav-link resourceLink" to="/create">
                      Create
                    </Link>
                  </li> : <></>
                )}
                {user.address && (
                  creatingCollStatus ? <li className="nav-item">
                    <Link style={{fontSize: "24px", fontWeight: "bold"}} className="nav-link resourceLink" to="/create-collection">
                      Create Collection
                    </Link>
                  </li> : <></>
                )}
                {user.address && (
                  <li className="nav-item">
                    <Link
                      style={{fontSize: "24px", fontWeight: "bold"}}
                      className="nav-link"
                      to={`/profile/${authedUser.authedUser.address}`}
                    >
                      My Profile
                    </Link>
                  </li>
                )}
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/welcome">
                    Welcome
                  </Link>
                </li> */}
                {/* {user.address && (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/settings/profile`}
                    >
                      Settings
                    </Link>
                  </li>
                )} */}
              </ul>
              <div className="col2">
                <div className="searchNav">
                <Select
                className="basic-single"
                classNamePrefix="lulla"
                isLoading={isLoading}
                isClearable={false}
                backspaceRemovesValue={false}
                isSearchable={true}
                placeholder={"Search"}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: '18px',
                    border: 'none'
                  }), 
                  contianer: (base) => ({
                    ...base,
                    width: '100%'
                  }),
                  singleValue: (base) => ({
                    ...base,
                    width: '100%'
                  })
                }}
                onInputChange={(value, e) => { 
                  console.log(e, value)           
                  if (e.action === 'menu-close' || e.action === 'input-blur') {}
                  else if (e.action === 'set-value'){
                    console.log(e);
                    setSearchVal('');
                  }
                  else { setSearchVal(value)}
                }}
                inputValue={searchVal}
                onSelectCapture={e => console.log(e)}
                name="color"
                getOptionLabel={(option) => {
                  if (option.address){
                    return option.address;
                  }
                  else{
                    return option.name;
                  }
                }}
                getOptionValue={(option) => {
                  return option._id
                }}
                formatOptionLabel={(option) => {
                  console.log("------", option)
                  if (option.nft_id){
                    return (
                      <div id="tmpdiv" onClick={() => navigate(`/detail/${option.nft_id}`)} style={{color: 'black'}} className="country-option">
                        {/* <img src={`${process.env.REACT_APP_BASE_URL}/${option.img_local}`} /> */}
                        <span><span style={{opacity: '0.6'}}>NFT:</span> {option.name}</span>
                      </div>
                    );
                  }
                  else if (option.address){
                    return(
                      <div id="tmpdiv" onClick={() => navigate(`/profile/${option.address}`)} style={{color: 'black'}} className="country-option">
                        {/* <img src={`${process.env.REACT_APP_BASE_URL}/${option.profilepic}`} alt="country-image" /> */}
                        <span><span style={{opacity: '0.6'}}>USR:</span> {option.displayName ? option.displayName : `${option.address.substring(0,5)}...${option.address.substring(38,44)}`}</span>
                      </div>
                    );
                  }
                  else{
                    return(
                      <div id="tmpdiv" onClick={() => navigate(`/collection/${option.trimmedname}`)} style={{color: 'black'}} className="country-option">
                        {/* <img src={`${process.env.REACT_APP_BASE_URL}/${option.coverImageObjId}`} alt="country-image" /> */}
                        <span><span style={{opacity: '0.6'}}>COL:</span> {option.name}</span>
                      </div>
                    );
                  }
                }}
                components={{
                  DropdownIndicator
                }}
                options={searchResults}
              >
              </Select>

                  {/* <img src={searchIco} alt="" />
                  <input type="text" placeholder="Search" /> */}
                </div>
              {(Object.keys(authedUser.authedUser).length === 0) ? (
                <button onClick={() => setShow(true)} className="walletBtn btn">
                  Connect Wallet
                </button>
              ) : (
                <div className="walletBtn btn" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <img style={{height: '20px', width: '20px', marginRight: '5px'}} className="ethIconBlack" src={bnbIco} />
                  {authedUser.authedUser.address.slice(0, 5)}...{authedUser.authedUser.address.slice(38, authedUser.authedUser.address.length)}
                </div> 
              )}
              {authedUser.authedUser.address ? (
                <>
                <div className="walletIco">
                  <img src={walletIco} alt="wallet" />
                  <div className="amount">
                    {balance?(balance / 1000000000000000000).toFixed(3) : 0} BNB
                  </div>
                </div>
                <div onClick={logoutUserReq} className={"logoutIcon"}>
                  <img src={logout} />
                  <span>Logout</span>
                </div>
                </>
              ) : <></>}
              </div>
            </div>
          </div>
        </nav>
      </header>

      <Modal centered={true} show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="WalletModal">
            <h1>Connect your web3 wallet</h1>
            <div className="walletRow">
              <div className="metamask">
                <div className="walletImg" style={{textAlign: 'center'}}>
                  <img className="img-fluid" style={{height: '65px'}} src={metamask} alt="metamask" />
                </div>
                <div className="btnDiv">
                  <button className=""  onClick={() => connectMetamask('m').then(() => handleClose())}>Connect Metamask</button>
                </div>
              </div>
              <div className="walletConnect">
                <div className="walletImg" style={{textAlign: 'center'}}>
                  <img className="img-fluid" style={{height: '65px'}} src={(walletConnect)} alt="metamask" />
                </div>
                <div className="btnDiv">
                  <button className="" onClick={() => connectMetamask('w').then(() => handleClose())} >WalletConnect</button>
                </div>
              </div>
            </div>
            <div className="helpDv">
              {/* <img src={help} alt="help" /> */}
              By connecting your wallet, you agree to the Disclaimer, Terms of Use, Privacy Policy and risks attached.
            </div>
            <div className="closeIcon" onClick={handleClose}>
            <i class="fal fa-times"></i>
            </div>
          </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
};
